@import 'style/vars.module';

.RouteContainer {
  height: 100%;
  width: 100%;
  min-height: calc(100vh - 56px);

  &.public {
    background-color: $white;
    margin-right: auto;
    margin-left: auto;
  }
  &.smallView {
    .BodyContainer,
    .PageContainer {
      max-width: 468px;
    }
  }

  @media (max-width: $breakpoint-mobile-max) {
    &.smallView {
      .BodyContainer,
      .PageContainer {
        max-width: 100%;
      }
    }
  }
}
