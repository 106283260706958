@import 'style/vars.module';

.bgLgreen025 {
  background-color: $lgreen025;
}
.bgYellow025 {
  background-color: $yellow025;
}
.bgOrange025 {
  background-color: $orange025;
}
.bgGreen025 {
  background-color: $green025;
}
.bgGreenG025 {
  background-color: $greenG025;
}
.bgGreenG050 {
  background-color: $greenG050;
}
.bgGreenG200 {
  background-color: $greenG200;
}
.bgGreenT025 {
  background-color: $greenT025;
}
.bgRed025 {
  background-color: $red025;
}
.bgGray0 {
  background-color: $gray0;
}
.bgGray1 {
  background-color: $gray1;
}
.bgGray3 {
  background-color: $gray3;
}

.bgGray050 {
  background-color: $gray050;
}

.bgGray005 {
  background-color: $gray005;
}

.bgGray025 {
  background-color: $gray025;
}

.bgGrayN025 {
  background-color: $grayN025;
}

.bgGrayN300 {
  background-color: $grayN300;
}

.bgGrayN040 {
  background-color: $grayN040;
}

.bgGray500 {
  background-color: $gray500;
}

.bgGrayN050 {
  background-color: $grayN050;
}

.bgGrayN060 {
  background-color: $gray060;
}

.bgNeutral250 {
  background-color: $neutral250;
}

.bgNeutralN500 {
  background-color: $neutralN500;
}

.bgLBlue0 {
  background-color: $lBlue0;
}
.bgLBlue1 {
  background-color: $lBlue1;
}
.bgLBlue2 {
  background-color: $lBlue2;
}
.bgLBlue3 {
  background-color: $lBlue3;
}
.bgLBlue4 {
  background-color: $lBlue4;
}
.bgLBlue5 {
  background-color: $lBlue5;
}
.bgLBlue6 {
  background-color: $lBlue6;
}
.bgLBlue {
  background-color: $lBlue7;
}
.bgBlue0 {
  background-color: $blue0;
}
.bgBlue015 {
  background-color: $blue015;
}
.bgLBlue085 {
  background-color: $lBlue085;
}
.bgBlue025 {
  background-color: $blue025;
}
.bgBlueB025 {
  background-color: $blueB025;
}
.bgBlue050 {
  background-color: $blue050;
}
.bgBlueB100 {
  background-color: $blueB100;
}
.bgBlueB400 {
  background-color: $blueB400;
}

.bgYellow050 {
  background-color: $yellow050;
}
.bgYellow7 {
  background-color: $yellow7;
}

.bgYellow075 {
  background-color: $yellow075;
}
.bgBlue1 {
  background-color: $blue1;
}
.bgBlue2 {
  background-color: $blue2;
}
.bgBlue3 {
  background-color: $blue3;
}
.bgBlue4 {
  background-color: $blue4;
}
.bgBlue5 {
  background-color: $blue5;
}
.bgBlue6 {
  background-color: $blue6;
}
.bgBlue100 {
  background-color: $blue100;
}
.bgWhite {
  background-color: $white;
}
.bgWhite0 {
  background-color: $white0;
}
.bgRed0 {
  background-color: $red0;
}
.bgRed1 {
  background-color: $red1;
}
.bgRed2 {
  background-color: $red2;
}
.bgRed3 {
  background-color: $red3;
}
.bgLgreen0 {
  background-color: $lgreen0;
}
.bgLgreen6 {
  background-color: $lgreen6;
}
.bgLgreen3 {
  background-color: $lgreen3;
}
.bgGreen3 {
  background-color: $green3;
}
.bgGreen100 {
  background-color: $green100;
}
.bgGreenG100 {
  background-color: $greenG100;
}
.bgGreenT100 {
  background-color: $greenT100;
}

.bgLgreen200 {
  background-color: $lgreen200;
}
.bgGreen0 {
  background-color: $green0;
}
.bgYellow0 {
  background-color: $yellow0;
}
.bgYellow1 {
  background-color: $yellow1;
}
.bgYellow2 {
  background-color: $yellow2;
}
.bgYellow3 {
  background-color: $yellow3;
}
.bgYellow7 {
  background-color: $yellow7;
}
.bgYellow8 {
  background-color: $yellow8;
}
.bgYellowT100 {
  background-color: $yellowT100;
}
.bgOrange0 {
  background-color: $orange0;
}
.bgOrange2 {
  background-color: $orange2;
}
.bgOrange3 {
  background-color: $orange3;
}

.bgRed100 {
  background-color: $red100;
}
.bgRedR100 {
  background-color: $redR100;
}
.bgRedR300 {
  background-color: $redR300;
}
.bgRed025 {
  background-color: $red025;
}
.bgPurple0 {
  background-color: $purple0;
}
.bgTransparent {
  background-color: transparent !important;
}
.bgYellow100 {
  background-color: $yellow100;
}

.bgGrey005 {
  background-color: $gray005;
}

.bgNeutral050 {
  background-color: $neutral050;
}

.bgNeutral075 {
  background-color: $neutral075;
}

.bgNeutral005 {
  background-color: $neutral005;
}

.bgNeutral600 {
  background-color: $neutral600;
}
.bgLightSage {
  background-color: $lightSage;
}
.bgNeutral025 {
  background-color: $neutral025;
}

.bgNeutral400 {
  background-color: $neutral400;
}

.bgNeutral450 {
  background-color: $neutral450;
}

.bgNeutral425 {
  background-color: $neutral425;
}

.bgNeutralN500 {
  background-color: $neutralN500;
}

.bgBlue015 {
  background-color: $blue015;
}

.bgTransunionPrimary400 {
  background-color: $transunionPrimary400;
}

@media (max-width: $breakpoint-tablet-max) {
  .t-bgWhite {
    background-color: $white;
  }
}
@media (max-width: $breakpoint-mobile-max) {
  .m-bgTransparent {
    background-color: transparent !important;
  }
  .m-bgNeutral025 {
    background-color: $neutral025;
  }
  .m-bgWhite {
    background-color: $white;
  }
}
