@import 'style/vars.module';

.autocomplete-container {
  width: 100%;
  position: absolute;
  z-index: 2;
}

.autocomplete-item {
  color: black;
  border: 1px solid $gray3;
  background-color: white;
  padding: 10px 15px 10px 15px;
}

.autocomplete-item-footer {
  background-color: white;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}

.is-active {
  background-color: $gray1;
}
