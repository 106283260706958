@import 'style/vars.module';
@import '../../style/text';

.anticon-question-circle {
  font-size: $font14;
}

.tooltipContent {
  display: flex;
}

.closeTooltipIcon {
  svg {
    g path:nth-child(2) {
      fill: $white;
    }
  }
}

.CSToolTip {
  margin-top: -2px;
  margin-left: 5px;
  &.textOnly {
    margin-left: 0px;
  }
}

.ant-tooltip {
  max-width: 330px;
  ul {
    padding-left: 16px;
  }
  &.blueTooltip {
    z-index: 6;
    .ant-tooltip-arrow-content {
      background-color: $secondary_light;
      border-bottom-color: $secondary_light;
      border-left-color: $secondary_light;
      border-top-color: $secondary_light;
      border-right-color: $secondary_light;
      top: 1px;
    }
    .ant-tooltip-inner {
      background-color: $secondary_light;
      max-width: 505px;
    }
  }
  &.primaryLightTooltip {
    .ant-tooltip-arrow-content {
      --antd-arrow-background-color: #{$primary_light};
    }
    .ant-tooltip-inner {
      background-color: $primary_light;
    }
  }

  &.sesameCashMenuDesktopTooltip {
    left: 240px !important;
    top: 379px !important;
  }

  &.sesameCashMenuMobileTooltip {
    left: 8px !important;
    top: 326px !important;
  }

  &.whiteTooltip {
    .ant-tooltip-arrow-content {
      background-color: $white;
      border-bottom-color: $white;
      border-left-color: $white;
      border-top-color: $white;
      border-right-color: $white;
    }
    .ant-tooltip-inner {
      background-color: $white;
    }
    .tooltipContent {
      color: #353c45;
    }
  }

  &.approvalOddsCSTooltip {
    max-width: 300px;
  }

  &.dtiCSTooltip {
    max-width: 350px;
  }

  &.annualIncomeCSTooltip {
    font-size: 12px;
    line-height: 1.33;
  }

  &.footerViewCSTooltip {
    z-index: 1900;
  }
}

@media (max-width: $breakpoint-mobile-max) {
  .CSToolTip {
    &.removeMarginsMobile {
      margin: 0px;
    }
  }
  .ant-tooltip {
    max-width: 310px;
    &.blueTooltip {
      .ant-tooltip-inner {
        background-color: $secondary_light;
        max-width: 304px;
      }
    }
  }

  .credit-usage-tooltip {
    max-width: 178px;
  }
}

@media (max-width: $breakpoint-mobile-max) {
  .footerViewCSTooltip {
    .ant-tooltip-arrow {
      left: 230px;
    }
  }
}
