@import 'customBrandStylePath/_brand.scss';

// Responsive breakpoints
$breakpoint-mobile-min: 320px;
$breakpoint-mobile-max: 599px;
$breakpoint-tablet-min: 600px;
$breakpoint-tablet-max: 1095px;
$breakpoint-desktop-min: 840px;

//gutters

$hztlGutter-mobile: 8px;

$white: #ffffff;
$white0: #f6f6f7;
$black: #000000;
$black0: #080808;
$black1: #18191a;
$black2: #27292b;
//colors
$gray0: #f8f9fa;
$gray1: #eceff2;
$gray2: #ced5de; // gray 50
$gray3: #a4b0c2;
$gray4: #8696ae; // gray 100
$gray5: #78869c;
$gray6: #647082; //gray 300
$gray7: #424a56; //gray 400
$gray8: #353c45; //gray 500

$gray005: #f8f9fa;
$gray025: #eceff2;
$gray050: #ced5de;
$gray060: #a6a7a9;
$gray075: #a4b0c2;
$gray100: #8696ae;
$gray200: #78869c;
$gray300: #647082;
$gray400: #424a56;
$gray500: #353c45;

$grayN300: #454748;
$grayN025: #f6f6f7;
$grayN040: #ededee;
$grayN050: #ececec;
$grayN250: #636466;

$neutral005: #f8f9fa;
$neutral025: #f6f6f7;
$neutral050: #ced5de;
$neutral075: #dbdbdb;
$neutral100: #8696ae;
$neutral150: #919294;
$neutral200: #78869c;
$neutral250: #78797b;
$neutral300: #647082;
$neutral350: #343638;
$neutral400: #27292b;
$neutral425: #202223;
$neutral450: #18191a;
$neutral500: #353c45;
$neutralN500: #080808;
$neutral600: #252c35;
$neutral800: #12161b;

$red025: #ffeeec;
$red050: #ffc7c3;
$red075: #ff7971;
$red100: #d13655;
$red200: #d83c33;
$red300: #b62a22;
$red400: #941c15;
$red500: #72100a;
$redR300: #b62a22;
$redR100: #fa5147;

$green025: #def5e6;
$green050: #a7e4bd;
$green075: #79d89c;
$green100: #24bd5c;
$green200: #189948;
$green300: #0e7736;
$green400: #0a571b;
$green500: #003413;
$greenG100: #23bd5b;
$greenT100: #aedc6d;

$greenG025: #d4f6dd;
$greenG400: #005720;
$greenG050: #b4e2c0;
$greenG200: hsl(142, 73%, 35%);

$blue015: #daf0ff;
$blue025: #dde9fa;
$blue050: #a4c5f2;
$blue075: #5593e7;
$blueB100: #0070f6;
$blueB200: #005ac7;
$blueB300: #134ea6;
$blue100: #1d6fe0;
$blue150: #1c6fe0;
$blue200: #1758c5;
$blue300: #0f3ea1;
$blue400: #082073;
$blue500: #15214b;

$blueB025: #badaff;
$blueB400: #183a7c;

$blueLB400: #3a7aa8;

$lgreen025: #f0fae6;
$lgreen050: #d9f1bd;
$lgreen075: #c6e39d;
$lgreen100: #a1de5b;
$lgreen200: #95bf59;
$lgreen300: #669f32;
$lgreen400: #648435;

$orange025: #fff3e5;
$orange050: #fccda5;
$orange075: #ffb47e;
$orange100: #f89147;
$orange200: #dd6919;
$orange300: #b84a11;
$orange400: #7e3a07;

$orangeO200: #e27322;

$yellow025: #fff0c4;
$yellow050: #ffe8a4;
$yellow075: #ffdb72;
$yellow100: #f5c946;
$yellow200: #e3ac09;
$yellow300: #b58700;
$yellow400: #8f7014;
$yellow500: #6d540a;

$red0: #f8e1e5;
$red1: #ecaebb; //not used
$red2: #dc687f; //not used
$red3: #d13655;
$red4: #b72b44; //not used
$red5: #931d2f; //red 400
$red6: #670f19;

$orange0: #fdecdd; //not used
$orange1: #fccda5;
$orange2: #f9a257;
$orange3: #f88320;
$orange4: #dd6919;
$orange5: #b84a11; //orange 400
$orange6: #852909;

$yellow0: #fff7e0;
$yellow1: #ffebab;
$yellow2: #ffda63;
$yellow3: #ffce2f;
$yellow4: #e5b425;
$yellow5: #bf901a;
$yellow6: #8c640d; //yellow 400
$yellow7: #f5c946; //yellow 100
$yellow8: #fff0c4; //yellow 025

$yellowT100: #f89147;

$lgreen0: #f0fae6;
$lgreen1: #d9f1bd;
$lgreen2: #b8e684;
$lgreen3: #a1de5b;
$lgreen5: #87c348;
$lgreen6: #669f32;
$lgreen7: #40711a; //lgreen 400

$green0: #def5e6;
$green1: #a7e4bd;
$green2: #5acd84;
$green3: #24bd5c;
$green4: #1ca349;
$green5: #138032;
$green6: #0a571b; // green  400

$greenT025: #ebffce; // green  400

$purple0: #ebdffa;
$purple1: #caaaf1;
$purple100: #7c2cde;
$purple2: #9c60e6;
$purple200: #6323c3;
$purple4: #6323c3;
$purple400: #2b0f73;
$purple5: #44179f;
$purple6: #2b0f73;

//need to depricate this naming convention
$lBlue0: #ecf7ff;
$lBlue1: #cceaff;
$lBlue2: #9fd8ff;
$lBlue3: #80ccff;
$lBlue4: #67b1e5;
$lBlue5: #478fbf;
$lBlue6: #26638c;
$lBlue7: #daf0ff;
$lBlue75: #62aaff;

$lBlue025: #ecf7ff;
$lBlue050: #c1e5ff;
$lBlue075: #9bd5ff;
$lBlue085: #85c2fa;
$lBlue100: #80ccff;
$lBlue200: #67b1e5;
$lBlue300: #478fbf;
$lBlue400: #26638c;

$blue0: #dde9fa; //blue025
$blue1: #a4c5f2;
$blue2: #5593e7;
$blue3: #1d6fe0; //blue 100
$blue4: #1758c5;
$blue5: #0f3ea1;
$blue6: #082073;

$white: #ffffff;

// partners
$transunionBlue: #00a6ca;
$transunionPrimary400: #004364;

$lightSage: #cff1c9;
// fonts

$font7: 7px;
$font8: 8px;
$font9: 9px;
$font10: 10px;
$font11: 11px;
$font12: 12px;
$font13: 13px;
$font14: 14px;
$font15: 15px;
$font16: 16px;
$font18: 18px;
$font20: 20px;
$font22: 22px;
$font23: 23px;
$font24: 24px;
$font26: 26px;
$font27: 27px;
$font28: 28px;
$font32: 32px;
$font33: 33px;
$font34: 34px;
$font36: 36px;
$font40: 40px;
$font44: 44px;
$font45: 45px;
$font48: 48px;
$font60: 60px;

// paddings
$pad0: 0px;
$pad4: 4px;
$pad8: 8px;
$pad10: 10px;
$pad12: 12px;
$pad16: 16px;
$pad18: 18px;
$pad20: 20px;
$pad24: 24px;
$pad28: 28px;
$pad30: 30px;
$pad32: 32px;
$pad34: 34px;
$pad36: 36px;
$pad40: 40px;
$pad48: 48px;
$pad52: 52px;
$pad56: 56px;
$pad60: 60px;
$pad120: 120px;
$pad160: 160px;

// fields
$fieldHeight: 56px;
$fieldWidth: 288px;

$buttonHeight: 40px;

// borders
$borderSolid1: solid 1px $gray050;
$borderRadius0: 0px;
$borderRadius4: 4px;
$borderRadius8: 8px;
$borderRadius10: 10px;
$borderRadius16: 16px;
$borderRadius28: 28px;
$borderRadius52: 52px;
$borderRadius60: 60px;
$borderRadius100: 100px;
// box shadows
$boxShadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
$boxShadow1: 0 0 4px rgba(0, 0, 0, 0.25);
$boxShadow2: 0px 0px 16px rgba(0, 0, 0, 0.12), 0px 0px 8px rgba(0, 0, 0, 0.08);

:export {
  white: $white;
  gray0: $gray0;
  gray1: $gray1;
  gray2: $gray2;
  gray3: $gray3;
  gray4: $gray4;
  gray5: $gray5;
  gray6: $gray6;
  gray7: $gray7;
  gray8: $gray8;
  gray060: $gray060;
  grayN050: $grayN050;
  grayN250: $grayN250;
  neutral005: $neutral005;
  neutral050: $neutral050;
  neutral250: $neutral250;
  neutral300: $neutral300;
  neutral400: $neutral400;
  neutral500: $neutral500;
  red0: $red0;
  red1: $red1;
  red2: $red2;
  red3: $red3;
  red4: $red4;
  red5: $red5;
  red6: $red6;
  blue0: $blue0;
  blue1: $blue1;
  blue2: $blue2;
  blue3: $blue3;
  blue4: $blue5;
  blue5: $blue5;
  blue6: $blue6;
  lBlue0: $lBlue0;
  lBlue1: $lBlue1;
  lBlue2: $lBlue2;
  lBlue3: $lBlue3;
  lBlue4: $lBlue4;
  lBlue5: $lBlue5;
  lBlue6: $lBlue6;
  yellow0: $yellow0;
  yellow1: $yellow1;
  yellow2: $yellow2;
  yellow3: $yellow3;
  yellow4: $yellow4;
  yellow5: $yellow5;
  yellow6: $yellow6;
  yellow7: $yellow7;
  green0: $green0;
  green1: $green1;
  green2: $green2;
  green3: $green3;
  green4: $green4;
  green5: $green5;
  green6: $green6;
  font14: $font14;
  orange0: $orange0;
  orange1: $orange1;
  orange2: $orange2;
  orange3: $orange3;
  orange4: $orange4;
  orange5: $orange5;
  orange6: $orange6;
  lgreen0: $lgreen0;
  lgreen1: $lgreen1;
  lgreen2: $lgreen2;
  lgreen3: $lgreen3;
  lgreen5: $lgreen5;
  lgreen6: $lgreen6;
  lgreen7: $lgreen7;
  gray0: $gray0;
  gray1: $gray1;
  gray2: $gray2;
  gray3: $gray3;
  gray4: $gray4;
  gray5: $gray5;
  gray6: $gray6;
  gray7: $gray7;
  gray8: $gray8;
  red0: $red0;
  red1: $red1;
  red2: $red2;
  red3: $red3;
  red4: $red4;
  red5: $red5;
  red6: $red6;
  blue0: $blue0;
  blue1: $blue1;
  blue2: $blue2;
  blue3: $blue3;
  blue4: $blue5;
  blue5: $blue5;
  blue6: $blue6;
  lBlue0: $lBlue0;
  lBlue1: $lBlue1;
  lBlue2: $lBlue2;
  lBlue3: $lBlue3;
  lBlue4: $lBlue4;
  lBlue5: $lBlue5;
  lBlue6: $lBlue6;
  yellow0: $yellow0;
  yellow1: $yellow1;
  yellow2: $yellow2;
  yellow3: $yellow3;
  yellow4: $yellow4;
  yellow5: $yellow5;
  yellow6: $yellow6;
  green0: $green0;
  green1: $green1;
  green2: $green2;
  green3: $green3;
  green4: $green4;
  green5: $green5;
  green6: $green6;
  font14: $font14;
  orange0: $orange0;
  orange1: $orange1;
  orange2: $orange2;
  orange3: $orange3;
  orange4: $orange4;
  orange5: $orange5;
  orange6: $orange6;
  lgreen0: $lgreen0;
  lgreen1: $lgreen1;
  lgreen2: $lgreen2;
  lgreen3: $lgreen3;
  lgreen5: $lgreen5;
  lgreen6: $lgreen6;
  lgreen7: $lgreen7;

  gray005: $gray005;
  gray025: $gray025;
  gray050: $gray050;
  gray075: $gray075;
  gray100: $gray100;
  gray200: $gray200;
  gray300: $gray300;
  gray400: $gray400;
  gray500: $gray500;
  red025: $red025;
  red050: $red050;
  red075: $red075;
  red100: $red100;
  red200: $red200;
  red300: $red300;
  red400: $red400;
  blue025: $blue025;
  blue050: $blue050;
  blue075: $blue075;
  blue100: $blue100;
  blue200: $blue200;
  blue300: $blue300;
  blue400: $blue400;
  blueB400: $blueB400;
  yellow025: $yellow025;
  yellow050: $yellow050;
  yellow075: $yellow075;
  yellow100: $yellow100;
  yellow200: $yellow200;
  yellow300: $yellow300;
  yellow400: $yellow400;
  green025: $green025;
  green050: $green050;
  green075: $green075;
  green100: $green100;
  green200: $green200;
  green300: $green300;
  green400: $green400;
  orange025: $orange025;
  orange050: $orange050;
  orange075: $orange075;
  orange100: $orange100;
  orange200: $orange200;
  orange300: $orange300;
  orange400: $orange400;
  orangeO200: $orangeO200;
  lgreen025: $lgreen025;
  lgreen050: $lgreen050;
  lgreen075: $lgreen075;
  lgreen100: $lgreen100;
  lgreen200: $lgreen200;
  lgreen300: $lgreen300;
  lgreen400: $lgreen400;
  purple400: $purple400;
  purple200: $purple200;
}
