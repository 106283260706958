@import 'style/vars.module';

.page-loading-mask {
  width: 100%;
  padding-top: 50px;
  min-height: 500px;

  .inner-box {
    margin-left: auto;
    margin-right: auto;
    max-width: 200px;
  }

  &.isExpanded {
    padding-top: 32px;
    min-height: 280px;
    .inner-box {
      max-width: 360px;
      .animation-wrapper {
        margin-left: auto;
        margin-right: auto;
      }
    }
    .loading-description {
      padding-top: 24px;
    }
  }

  .loading-description {
    padding-top: 50px;
  }

  @media screen and (max-width: $breakpoint-mobile-max) {
    &.isExpanded {
      padding-top: 24px;
      min-height: initial;
      .inner-box {
        max-width: 300px;
      }
      .loading-description {
        padding-top: 14px;
      }
    }
  }
}
