@import 'style/vars.module';

.PageContainer {
  width: 1020px;

  &.signup {
    max-width: 960px;
    padding-bottom: 120px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: $breakpoint-mobile-max) {
  .PageContainer {
    &.desktop-row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: flex-start;
    }
  }
}

@media (max-width: $breakpoint-tablet-max) {
  .PageContainer {
    width: 100%;
  }
}

@media (max-width: $breakpoint-mobile-max) {
  .PageContainer {
    padding-left: 0px;
    padding-right: 0px;
    &.signup {
      max-width: 100%;
      padding-bottom: 180px;
    }

    &.mobile-stack {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
    }
  }
}
