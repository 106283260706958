@import 'style/vars.module';

// if you need the color hexcode without back ground color style.

$system_neutral_n000: $white;
$system_neutral_n005: $neutral005;
$system_neutral_n025: $neutral025;
$system_neutral_n050: $grayN050;
$system_neutral_n075: $neutral075;
$system_neutral_n050: $grayN050;
$system_neutral_n100: $gray060;
$system_neutral_n200: $neutral250;
$system_neutral_n250: $grayN250;
$system_neutral_n300: $grayN300;
$system_neutral_n350: $neutral350;
$system_neutral_n400: $neutral400;
$system_neutral_n450: $neutral450;
$system_neutral_n500: $neutralN500;
$system_info_i025: $blueB025;
$system_info_i050: $lBlue085;
$system_info_i075: $lBlue75;
$system_info_i100: $blueB100;
$system_info_i200: $blueB200;
$system_info_i300: $blueB300;
$system_info_i400: $blueB400;
$system_info_i500: $blue500;
$system_ltblue_lb025: $blue015;
$system_ltblue_lb050: $lBlue050;
$system_ltblue_lb075: $lBlue075;
$system_ltblue_lb400: $blueLB400;
$system_success_s025: $greenG025;
$system_success_s050: $greenG050;
$system_success_s075: $green075;
$system_success_s100: $greenG100;
$system_success_s200: $green200;
$system_success_s300: $green300;
$system_success_s400: $greenG400;
$system_success_s500: $green500;
$system_ltgreen_lg025: $greenT025;
$system_ltgreen_lg050: $lgreen050;
$system_ltgreen_lg075: $lgreen075;
$system_ltgreen_lg100: $greenT100;
$system_ltgreen_lg200: $lgreen200;
$system_ltgreen_lg400: $lgreen400;
$system_warn_w025: $yellow025;
$system_warn_w050: $yellow050;
$system_warn_w075: $yellow075;
$system_warn_w100: $yellow100;
$system_warn_w200: $yellow200;
$system_warn_w300: $yellow300;
$system_warn_w400: $yellow400;
$system_warn_w500: $yellow500;
$system_orange_o025: $orange025;
$system_orange_o075: $orange075;
$system_orange_o100: $orange100;
$system_orange_o200: $orangeO200;
$system_orange_o400: $orange400;
$system_error_e025: $red025;
$system_error_e050: $red050;
$system_error_e075: $red075;
$system_error_e100: $redR100;
$system_error_e200: $red200;
$system_error_e300: $red300;
$system_error_e400: $red400;
$system_error_e500: $red500;

//system colors should never change by brand!!!

.system_neutral_n000 {
  background-color: $system_neutral_n000;
}

.system_neutral_n005 {
  background-color: $system_neutral_n005;
}

.system_neutral_n025 {
  background-color: $system_neutral_n025;
}

.system_neutral_n050 {
  background-color: $system_neutral_n050;
}

.system_neutral_n075 {
  background-color: $system_neutral_n075;
}

.system_neutral_n100 {
  background-color: $system_neutral_n100;
}

.system_neutral_n200 {
  background-color: $system_neutral_n200;
}

.system_neutral_n250 {
  background-color: $system_neutral_n250;
}

.system_neutral_n300 {
  background-color: $system_neutral_n300;
}

.system_neutral_n350 {
  background-color: $system_neutral_n350;
}

.system_neutral_n400 {
  background-color: $system_neutral_n400;
}

.system_neutral_n450 {
  background-color: $system_neutral_n450;
}

.system_neutral_n500 {
  background-color: $system_neutral_n500;
}

.system_info_i025 {
  background-color: $system_info_i025;
}

.system_info_i050 {
  background-color: $system_info_i050;
}

.system_info_i075 {
  background-color: $system_info_i075;
}

.system_info_i100 {
  background-color: $system_info_i100;
}

.system_info_i200 {
  background-color: $system_info_i200;
}

.system_info_i300 {
  background-color: $system_info_i300;
}

.system_info_i400 {
  background-color: $system_info_i400;
}

.system_info_i500 {
  background-color: $system_info_i500;
}

.system_ltblue_lb025 {
  background-color: $system_ltblue_lb025;
}

.system_ltblue_lb050 {
  background-color: $system_ltblue_lb050;
}

.system_ltblue_lb075 {
  background-color: $system_ltblue_lb075;
}

.system_ltblue_lb400 {
  background-color: $system_ltblue_lb400;
}

.system_success_s025 {
  background-color: $system_success_s025;
}

.system_success_s050 {
  background-color: $system_success_s050;
}

.system_success_s075 {
  background-color: $system_success_s075;
}

.system_success_s100 {
  background-color: $system_success_s100;
}

.system_success_s200 {
  background-color: $system_success_s200;
}

.system_success_s300 {
  background-color: $system_success_s300;
}

.system_success_s400 {
  background-color: $system_success_s400;
}

.system_success_s500 {
  background-color: $system_success_s500;
}

.system_ltgreen_lg025 {
  background-color: $system_ltgreen_lg025;
}

.system_ltgreen_lg050 {
  background-color: $system_ltgreen_lg050;
}

.system_ltgreen_lg075 {
  background-color: $system_ltgreen_lg075;
}

.system_ltgreen_lg100 {
  background-color: $system_ltgreen_lg100;
}

.system_ltgreen_lg200 {
  background-color: $system_ltgreen_lg200;
}

.system_ltgreen_lg400 {
  background-color: $system_ltgreen_lg400;
}

.system_warn_w025 {
  background-color: $system_warn_w025;
}

.system_warn_w050 {
  background-color: $system_warn_w050;
}

.system_warn_w075 {
  background-color: $system_warn_w075;
}

.system_warn_w100 {
  background-color: $system_warn_w100;
}

.system_warn_w200 {
  background-color: $system_warn_w200;
}

.system_warn_w300 {
  background-color: $system_warn_w300;
}

.system_warn_w400 {
  background-color: $system_warn_w400;
}

.system_warn_w500 {
  background-color: $system_warn_w500;
}

.system_orange_o025 {
  background-color: $system_orange_o025;
}

.system_orange_o075 {
  background-color: $system_orange_o075;
}

.system_orange_o100 {
  background-color: $system_orange_o100;
}

.system_orange_o400 {
  background-color: $system_orange_o400;
}

.system_error_e025 {
  background-color: $system_error_e025;
}

.system_error_e050 {
  background-color: $system_error_e050;
}

.system_error_e075 {
  background-color: $system_error_e075;
}

.system_error_e100 {
  background-color: $system_error_e100;
}

.system_error_e200 {
  background-color: $system_error_e200;
}

.system_error_e300 {
  background-color: $system_error_e300;
}

.system_error_e400 {
  background-color: $system_error_e400;
}

.system_error_e500 {
  background-color: $system_error_e500;
}

.system_neutral_n000_text {
  color: $system_neutral_n000;
}

.system_neutral_n005_text {
  color: $system_neutral_n005;
}

.system_neutral_n025_text {
  color: $system_neutral_n025;
}

.system_neutral_n050_text {
  color: $system_neutral_n050;
}

.system_neutral_n075_text {
  color: $system_neutral_n075;
}

.system_neutral_n100_text {
  color: $system_neutral_n100;
}

.system_neutral_n200_text {
  color: $system_neutral_n200;
}

.system_neutral_n250_text {
  color: $system_neutral_n250;
}

.system_neutral_n300_text {
  color: $system_neutral_n300;
}

.system_neutral_n350_text {
  color: $system_neutral_n350;
}

.system_neutral_n400_text {
  color: $system_neutral_n400;
}

.system_neutral_n450_text {
  color: $system_neutral_n450;
}

.system_neutral_n500_text {
  color: $system_neutral_n500;
}

.system_info_i025_text {
  color: $system_info_i025;
}

.system_info_i050_text {
  color: $system_info_i050;
}

.system_info_i075_text {
  color: $system_info_i075;
}

.system_info_i100_text {
  color: $system_info_i100;
}

.system_info_i200_text {
  color: $system_info_i200;
}

.system_info_i300_text {
  color: $system_info_i300;
}

.system_info_i400_text {
  color: $system_info_i400;
}

.system_info_i500_text {
  color: $system_info_i500;
}

.system_ltblue_lb025_text {
  color: $system_ltblue_lb025;
}

.system_ltblue_lb050_text {
  color: $system_ltblue_lb050;
}

.system_ltblue_lb075_text {
  color: $system_ltblue_lb075;
}

.system_ltblue_lb400_text {
  color: $system_ltblue_lb400;
}

.system_success_s025_text {
  color: $system_success_s025;
}

.system_success_s050_text {
  color: $system_success_s050;
}

.system_success_s075_text {
  color: $system_success_s075;
}

.system_success_s100_text {
  color: $system_success_s100;
}

.system_success_s200_text {
  color: $system_success_s200;
}

.system_success_s300_text {
  color: $system_success_s300;
}

.system_success_s400_text {
  color: $system_success_s400;
}

.system_success_s500_text {
  color: $system_success_s500;
}

.system_ltgreen_lg025_text {
  color: $system_ltgreen_lg025;
}

.system_ltgreen_lg050_text {
  color: $system_ltgreen_lg050;
}

.system_ltgreen_lg075_text {
  color: $system_ltgreen_lg075;
}

.system_ltgreen_lg100_text {
  color: $system_ltgreen_lg100;
}

.system_ltgreen_lg200_text {
  color: $system_ltgreen_lg200;
}

.system_ltgreen_lg400_text {
  color: $system_ltgreen_lg400;
}

.system_warn_w025_text {
  color: $system_warn_w025;
}

.system_warn_w050_text {
  color: $system_warn_w050;
}

.system_warn_w075_text {
  color: $system_warn_w075;
}

.system_warn_w100_text {
  color: $system_warn_w100;
}

.system_warn_w200_text {
  color: $system_warn_w200;
}

.system_warn_w300_text {
  color: $system_warn_w300;
}

.system_warn_w400_text {
  color: $system_warn_w400;
}

.system_warn_w500_text {
  color: $system_warn_w500;
}

.system_orange_o025_text {
  color: $system_orange_o025;
}

.system_orange_o075_text {
  color: $system_orange_o075;
}

.system_orange_o100_text {
  color: $system_orange_o100;
}

.system_orange_o400_text {
  color: $system_orange_o400;
}

.system_error_e025_text {
  color: $system_error_e025;
}

.system_error_e050_text {
  color: $system_error_e050;
}

.system_error_e075_text {
  color: $system_error_e075;
}

.system_error_e100_text {
  color: $system_error_e100;
}

.system_error_e200_text {
  color: $system_error_e200;
}

.system_error_e300_text {
  color: $system_error_e300;
}

.system_error_e400_text {
  color: $system_error_e400;
}

.system_error_e500_text {
  color: $system_error_e500;
}

:export {
  system_neutral_n000: $system_neutral_n000;
  system_neutral_n005: $system_neutral_n005;
  system_neutral_n025: $system_neutral_n025;
  system_neutral_n050: $system_neutral_n050;
  system_neutral_n075: $system_neutral_n075;
  system_neutral_n100: $system_neutral_n100;
  system_neutral_n200: $system_neutral_n200;
  system_neutral_n250: $system_neutral_n250;
  system_neutral_n300: $system_neutral_n300;
  system_neutral_n350: $system_neutral_n350;
  system_neutral_n400: $system_neutral_n400;
  system_neutral_n450: $system_neutral_n450;
  system_neutral_n500: $system_neutral_n500;
  system_info_i025: $system_info_i025;
  system_info_i050: $system_info_i050;
  system_info_i075: $system_info_i075;
  system_info_i100: $system_info_i100;
  system_info_i200: $system_info_i200;
  system_info_i300: $system_info_i300;
  system_info_i400: $system_info_i400;
  system_info_i500: $system_info_i500;
  system_ltblue_lb025: $system_ltblue_lb025;
  system_ltblue_lb050: $system_ltblue_lb050;
  system_ltblue_lb075: $system_ltblue_lb075;
  system_ltblue_lb400: $system_ltblue_lb400;
  system_success_s025: $system_success_s025;
  system_success_s050: $system_success_s050;
  system_success_s075: $system_success_s075;
  system_success_s100: $system_success_s100;
  system_success_s200: $system_success_s200;
  system_success_s300: $system_success_s300;
  system_success_s400: $system_success_s400;
  system_success_s500: $system_success_s500;
  system_ltgreen_lg025: $system_ltgreen_lg025;
  system_ltgreen_lg050: $system_ltgreen_lg050;
  system_ltgreen_lg075: $system_ltgreen_lg075;
  system_ltgreen_lg100: $system_ltgreen_lg100;
  system_ltgreen_lg200: $system_ltgreen_lg200;
  system_ltgreen_lg400: $system_ltgreen_lg400;
  system_warn_w025: $system_warn_w025;
  system_warn_w050: $system_warn_w050;
  system_warn_w075: $system_warn_w075;
  system_warn_w100: $system_warn_w100;
  system_warn_w200: $system_warn_w200;
  system_warn_w300: $system_warn_w300;
  system_warn_w400: $system_warn_w400;
  system_warn_w500: $system_warn_w500;
  system_orange_o025: $system_orange_o025;
  system_orange_o075: $system_orange_o075;
  system_orange_o100: $system_orange_o100;
  system_orange_o200: $system_orange_o200;
  system_orange_o400: $system_orange_o400;
  system_error_e025: $system_error_e025;
  system_error_e050: $system_error_e050;
  system_error_e075: $system_error_e075;
  system_error_e100: $system_error_e100;
  system_error_e200: $system_error_e200;
  system_error_e300: $system_error_e300;
  system_error_e400: $system_error_e400;
  system_error_e500: $system_error_e500;
}
