@import 'style/vars.module';

@media (max-width: $breakpoint-mobile-max) {
  .main-main-container {
    .main-nav-container.show-global-header {
      height: 112px;
      & > *,
      & > .flex-container {
        height: 56px;
      }

      .continue-to-app-banner {
        border-bottom: solid 1px $gray2;
      }
      & + .main-body-container {
        padding-top: 112px;
      }
    }
  }

  .global-header-app-download-container {
    .global-header-app-download-btn {
      padding: 0 8px;
    }
  }
}
