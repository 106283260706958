@import 'style/vars.module';

.sessionTimeout {
  .ant-modal {
    top: 150px;
  }
  .ant-modal-footer {
    border-top: none;
    position: relative;
    padding: 20px 20px;
  }
  .ant-modal-body {
    height: 100%;
    overflow: auto;
    padding-top: 56px;
    padding-left: 56px;
    padding-right: 56px;
    padding-bottom: 0px !important;
  }

  @media (max-width: $breakpoint-tablet-max) {
    .ant-modal {
      top: 0px;
    }
  }

  @media (max-width: $breakpoint-mobile-max) {
    .ant-modal {
      top: 0px;
    }
    .ant-modal-body {
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 0px;
    }
    .ant-modal-content {
      width: auto;
    }
    .ant-modal-footer button + button {
      margin-left: 0px;
    }
  }
}
