@import 'style/vars.module';

.footer {
  padding: 0px 0px;
  flex-shrink: 0;

  .footer-line {
    border-top: 1px solid $gray050;
  }

  .footer-link-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .footer-copyright {
    color: #647082;
  }

  .footer-links {
    span:nth-child(1) {
      margin-right: 5px;
    }

    a:focus-visible {
      outline: 2px solid #000000;
    }
  }

  .footer-tu {
    color: #647082;
    margin-right: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .footer-tu-text {
      margin-right: 5px;
      height: 6px;
    }
    .footer-tu-logo {
      margin-left: 5px;
    }
  }
}

@media (max-width: $breakpoint-tablet-max) {
  .footer {
    padding: 0 16px 24px;
    .footer-link-container {
      display: flex;
      flex-direction: column;
    }
  }
  .ant-tooltip-placement-topRight .ant-tooltip-arrow {
    left: 30%;
  }
}

.ant-popover {
  .ant-popover-inner-content {
    .footer-popover {
      width: 200px;
    }
  }
}
