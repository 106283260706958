@import url('https://fonts.googleapis.com/css?family=Lato:400,400i,700,900&display=swap');

$branding_background_image_path: '';
$primary_light: #0070f6;
$secondary_light: #12161b;
$primary_cta_bg_light: #0070f6;
$primary_cta_bg_dark: #71c4ff;
$primary_cta_text_light: #ffffff;
$secondary_cta_bg_light: #0070f6;
$secondary_cta_text_light: #ffffff;

$font-family-base: 'Lato';
$font-family-regular: 'Lato Regular';
$font-family-bold: 'Lato Bold';
$font-family-black: 'Lato Black';

.primary_light {
  background-color: $primary_light;
}

.secondary_light {
  background-color: $secondary_light;
}

.primary_cta_bg_light {
  background-color: $primary_cta_bg_light;
}

.primary_cta_bg_dark {
  background-color: $primary_cta_bg_dark;
}

.primary_text {
  color: $primary_cta_bg_light;
}

.primary_text_light {
  color: $primary_light;
}

.primary_cta_text_light {
  color: $primary_cta_text_light;
}

.secondary_cta_bg_light {
  background-color: $secondary_cta_bg_light;
}

.secondary_text_light {
  color: $secondary_cta_bg_light;
}

.secondary_border_light {
  border: 1px solid $secondary_cta_bg_light;
}

.secondary_cta_text_light {
  color: $secondary_cta_text_light;
}

@mixin font-base {
  font-family: $font-family-base;
}

@mixin font-regular {
  font-family: $font-family-regular;
}

@mixin font-bold {
  font-family: $font-family-bold;
}

@mixin font-black {
  font-family: $font-family-black;
}

// exception case: instruction view EPOD-6585
$continue_btn_free_color: $secondary_cta_bg_light;
$continue_btn_premium_color: #f5c946;
$continue_btn_free_bg: $primary_cta_text_light;
$explore_cs_grade_free_color: #f5c946;
$explore_cs_grade_premium_color: #f5c946;
// exception case: instruction view EPOD-7170
$plus-user-background: #202223;
$gauge_dial_text_color: $primary_cta_bg_light;

:export {
  primary_light: $primary_light;
  secondary_light: $secondary_light;
  primary_cta_bg_light: $primary_cta_bg_light;
  primary_cta_text_light: $primary_cta_text_light;
  primary_cta_bg_dark: $primary_cta_bg_dark;
  secondary_cta_bg_light: $secondary_cta_bg_light;
  secondary_cta_text_light: $secondary_cta_text_light;
  font-family-base: $font-family-base;
  font-family-regular: $font-family-regular;
  font-family-bold: $font-family-bold;
  font-family-black: $font-family-black;
}
