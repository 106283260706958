@import 'style/vars.module';

/* Error Page */
.errorPage {
  position: relative;

  .ErrorTemplate {
    width: 100%;
    height: 100%;

    .Error {
      text-align: center;
      max-width: 498px;
      width: 100%;
    }
    .fillHeight {
      height: calc(100vh - 56px);
    }

    .footer {
      margin-top: auto;
      width: 100%;
      background-color: $white;
    }
  }
  .errormask-image {
    max-width: 498px;
    width: 100%;
  }
  @media (max-width: $breakpoint-mobile-max) {
    max-width: 100%;
  }
}
