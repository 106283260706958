@import 'style/vars.module';

@media (min-width: $breakpoint-tablet-max) {
  .mobileMenu {
    display: none !important;
  }
}

@media (max-width: $breakpoint-tablet-max) {
  .menuDotIconSection {
    position: relative;
  }
  .menuDot {
    width: 8px;
    height: 8px;
    background-color: $red100;
    border-radius: 16px;
    position: absolute;
    right: 29px;
    top: -4px;
  }

  ul.mobileMenu {
    display: flex;
    justify-content: space-around;
    font-size: $font8;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    width: 100%;

    .redDotCircle {
      background-color: $red2;
      border-radius: 50px;
      top: -45px;
      left: 70%;
    }

    &.plusMobileNavbar {
      background-color: $secondary_light;
      li {
        a {
          .mobileMenuItemWrapper {
            &:active {
              background-color: $black1;
            }
          }
        }
        a.selected {
          svg {
            g path:nth-child(2) {
              fill: $primary_cta_bg_light;
            }
          }
          .mobileMenuItemWrapper {
            border-bottom: 2px solid $primary_cta_bg_light;

            .outline {
              display: none;
            }
            .filled {
              display: block;
            }
            .mobileMenuItemText {
              color: $primary_cta_bg_light;
            }
            .mobileMenuItemPlusText {
              color: $primary_cta_bg_light;
            }
          }
        }
      }
    }

    li {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      > div {
        width: 100%;
        height: 100%;
      }
      a {
        width: 100%;
        text-align: center;
        display: block;
        height: 100%;
        text-decoration: none;
        &.selected {
          svg {
            g path:nth-child(2) {
              fill: $secondary_cta_bg_light;
            }
          }
          .mobileMenuItemWrapper {
            border-bottom: 2px solid $secondary_cta_bg_light;

            .outline {
              display: none;
            }
            .filled {
              display: block;
            }
            .mobileMenuItemText {
              color: $secondary_cta_bg_light;
            }
          }
        }

        svg {
          height: 24px;
          width: 24px;
          align-self: center;
        }
        .mobileMenuItemWrapper {
          position: relative;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          box-sizing: border-box;

          .mobileIconContainer {
            display: inline-block;
            width: fit-content;
            align-self: center;
            position: relative;
            .hightlightFeature {
              top: -5px;
              right: -7px;
            }
          }

          .outline {
            display: block;
          }
          .filled {
            display: none;
          }
          .mobileMenuItemText {
            font-size: $font8;
            color: $gray7;
          }
          .mobileMenuItemPlusText {
            font-size: $font8;
            color: $secondary_cta_text_light;
          }
          &:active {
            background-color: $gray005;
          }
        }
      }
    }
  }
  ul.gapForOCF {
    gap: 40%;
  }
  .menuDotIconSection.menuDotPlusIconSection {
    svg {
      g path:nth-child(2) {
        fill: $secondary_cta_text_light;
      }
    }
  }

  .menuDotPlusIconSections {
    .outline {
      g path:nth-child(2) {
        fill: $secondary_cta_text_light;
      }
    }
  }
}
