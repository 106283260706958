@import 'style/vars.module';

.notification-container {
  position: absolute;
  z-index: 1001;

  & .ContainerCorrection {
    position: fixed;
    right: 0;
    top: 56px;
    padding: 16px 24px;
  }
}

.notification {
  padding: 12px 12px;
  overflow: hidden;
  line-height: 1.5;
  background: #fff;
  border-radius: 4px;
  transition: transform 0.3s ease-out;
  display: flex;
  z-index: 5;

  &.floater {
    position: relative;
    margin: 16px;
    max-width: 288px;
  }
  &.inline-row-notification {
    margin-left: 0px;
    margin-right: 0px;
    position: inherit;
    width: 100%;
  }
  .notification-title {
    padding-top: 1px;
  }
}

@media (max-width: $breakpoint-tablet-max) {
  .notification-container {
    width: 100%;

    & .ContainerCorrection {
      padding: 16px 16px;
    }
  }

  .notification {
    .floater {
      width: 100%;
    }
  }
}

.notification-body {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 8px;
  padding-right: 8px;
}

.notification-close-button {
  margin-top: -5px;
}

.notification-info {
  border: solid 1px $blue6;
  background-color: $blue0;
}
.notification-warning {
  border: solid 1px $yellow6;
  background-color: $yellow0;
}
.notification-success {
  border: solid 1px $green6;
  background-color: $green0;
}
.notification-error {
  border: solid 1px $red6;
  background-color: $red0;
  z-index: 3;
}

@-webkit-keyframes animate-slide-top-left {
  from {
    margin-left: -100%;
  }
  to {
    margin-left: 0;
  }
}
@keyframes animate-slide-top-left {
  from {
    margin-left: -100%;
  }
  to {
    margin-left: 0;
  }
}

@-webkit-keyframes animate-slide-top-right {
  from {
    margin-right: -300px;
  }
  to {
    margin-right: 0;
  }
}
@keyframes animate-slide-top-right {
  from {
    margin-right: -300px;
  }
  to {
    margin-right: 0;
  }
}

@-webkit-keyframes animate-slide-bottom-left {
  from {
    margin-left: -100%;
  }
  to {
    margin-left: 0;
  }
}
@keyframes animate-slide-bottom-left {
  from {
    margin-left: -100%;
  }
  to {
    margin-left: 0;
  }
}

@-webkit-keyframes animate-slide-bottom-right {
  from {
    margin-right: -100%;
  }
  to {
    margin-right: 0;
  }
}
@keyframes animate-slide-bottom-right {
  from {
    margin-right: -100%;
  }
  to {
    margin-right: 0;
  }
}

.top-left {
  top: 0;
  left: 0;
  -webkit-animation: 0.5s linear 0s animate-slide-top-left;
  animation: 0.5s linear 0s animate-slide-top-left;
}

.top-right {
  top: 0;
  right: 0;
  -webkit-animation: 0.5s linear 0s animate-slide-top-right;
  animation: 0.5s linear 0s animate-slide-top-right;
}

.bottom-left {
  bottom: 0;
  left: 0;
  -webkit-animation: 0.5s linear 0s animate-slide-bottom-left;
  animation: 0.5s linear 0s animate-slide-bottom-left;
}

.bottom-right {
  bottom: 0;
  right: 0;
  -webkit-animation: 0.5s linear 0s animate-slide-bottom-right;
  animation: 0.5s linear 0s animate-slide-bottom-right;
}
