@import 'style/vars.module';

.responsiveModal {
  .headerTitleSection {
    max-width: 75vw;
  }
  .responsiveModalContent {
    width: 100%;
  }

  .closeIcon {
    cursor: pointer;
  }

  .ant-modal {
    top: 25px;
    padding-bottom: $pad0;
    .ant-modal-content {
      border-radius: 8px;
      .ant-modal-header {
        padding: $pad16;
        position: relative;
        border-bottom: none;
        z-index: 1000;
        border-radius: 8px 8px 0px 0px;
        min-height: 60px;
        @media (max-width: $breakpoint-tablet-max) {
          min-height: 80px;
        }
      }
      .ant-modal-body {
        padding: $pad48;
        padding-bottom: $pad56;
        padding-top: $pad0;
        overflow: auto;
        max-height: calc(100vh - 200px);
      }
      .ant-modal-footer {
        position: relative;
        border-top: none;
        box-shadow: $boxShadow;
        padding: $pad16;
      }
    }
  }

  &.removeBotShadow {
    .ant-modal {
      .ant-modal-content {
        .ant-modal-footer {
          box-shadow: none;
        }
      }
    }
  }

  &.noFooter {
    .ant-modal-footer {
      display: none;
    }
  }

  &.noFooterDesktop {
    .ant-modal {
      .ant-modal-body {
        padding-bottom: 40px;
      }
      .ant-modal-footer {
        display: none;
      }
    }
  }

  &.titleAndArrow,
  &.noTitle {
    .ant-modal-header {
      padding-left: $pad16;
    }
  }

  .modalClose {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &.shadow {
    .ant-modal-header {
      box-shadow: $boxShadow;
      position: relative;
    }
  }

  &.noShadow {
    .ant-modal-header {
      box-shadow: none;
      position: relative;
    }
  }

  &.iframe {
    .ant-modal {
      .ant-modal-content {
        .ant-modal-body {
          height: calc(100vh - 200px);
          padding-bottom: $pad0;
          overflow: hidden;
          .responsiveModalContent {
            height: 100%;
          }
        }
      }
    }
  }

  @media (max-width: $breakpoint-tablet-max) {
    .modalHeaderTitle {
      font-size: $font20;
      font-weight: bold;
    }

    .ant-modal {
      display: flex;
      margin: 0px;
      width: 100% !important;
      height: 100%;
      max-width: 100%;
      top: 0;
      bottom: 0;
      .ant-modal-header {
        padding-left: $pad16;
      }
      .ant-modal-content {
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 0px;
        display: flex;
        flex-direction: column;
        .ant-modal-header {
          padding: $pad16;
        }
        .ant-modal-body {
          padding: $pad16;
          padding-top: $pad0;
          width: 100%;
          height: 100%;
          max-height: 100%;
          // position: fixed;
          padding-bottom: 200px;
        }
        .ant-modal-footer {
          padding: 16px;
          text-align: right;
          background: $white;
          border-radius: 0 0 4px 4px;
          // position: fixed !important;
          width: 100%;
          bottom: 0;
        }
      }
    }
    &.ant-modal-centered {
      .ant-modal {
        display: inline-block;
      }
    }
    &.withFooter {
      .ant-modal {
        .ant-modal-content {
          width: 100% !important;
          position: relative;
          .ant-modal-body {
            height: 100%;
            padding-bottom: 217px;
          }
        }
      }
    }
    &.noFooter {
      .ant-modal {
        .ant-modal-content {
          .ant-modal-body {
            padding-bottom: $pad120;
          }
        }
      }
    }
    &.noFooterDesktop {
      .ant-modal {
        .ant-modal-footer {
          display: initial;
        }
      }
    }
    &.iframe {
      .ant-modal {
        .ant-modal-content {
          .ant-modal-body {
            height: 100%;
            padding-bottom: $pad60;
          }
        }
      }
    }
    &.contentFullHeight {
      .ant-modal {
        .ant-modal-content {
          .ant-modal-body {
            padding-bottom: $pad40;
            .responsiveModalContent {
              height: 100%;
            }
          }
        }
      }
    }
    &.mobileBody-padLR8 {
      .ant-modal-content {
        .ant-modal-body {
          padding: $pad8;
          padding-top: $pad0;
        }
      }
    }
    .ant-modal {
      .ant-modal-content {
        .ant-modal-footer button + button {
          margin-left: 0px;
        }
      }
    }
  }
}
