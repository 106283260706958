.isSignup {
  .page-loading-mask {
    height: calc(100vh - 56px);
    .inner-box {
      max-width: 100%;
      min-width: 320px;
    }
    .inner-box .animation-wrapper {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
