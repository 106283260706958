@import 'style/vars.module';

.poof {
  display: none;
}
.poof-hard {
  display: none !important;
}
.desktopHide {
  display: none;
}

.desktopOnlyHide {
  display: none !important;
}

.inlineBlock {
  display: inline-block;
}

.inline {
  display: inline;
}

.block {
  display: block;
}

.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.mobileHide {
  display: initial !important;
}

.desktopHide {
  display: none !important;
}

@media (max-width: $breakpoint-tablet-max) {
  .mobileHide {
    display: none !important;
  }

  .desktopHide {
    display: initial !important;
  }

  .tabletHide {
    display: none !important;
  }

  .tabletShow {
    display: flex !important;
  }
}

@media (max-width: $breakpoint-mobile-max) {
  .mobileOnlyHide {
    display: none !important;
  }
  .desktopOnlyHide {
    display: flex !important;
  }
  .m-block {
    display: block;
  }
  .m-inline {
    display: inline;
  }
}

@media (min-width: $breakpoint-mobile-max) {
  .nonMobileHide {
    display: none !important;
  }
}
