.Badge {
  border-radius: 50%;
  padding: 0;
  text-align: center;

  &.dot {
    width: 8px;
    height: 8px;
  }
  &.numbered {
    line-height: 20px;
    font-size: 10px;
    height: 20px;
    width: 100%;
    max-width: 20px;

    &.wide {
      max-width: 27px;
      padding-left: 4px;
      padding-right: 4px;
      border-radius: 12px;
    }
  }
  &.txt {
    border-radius: 12px;
    min-width: 40px;
    font-size: 10px;
    line-height: 20px;
  }
}
