@import 'vars.module';
@import 'style/system_colors.scss';
@import 'customBrandStylePath/_brand.scss';

@import './text/_font_prebuilt_classes.scss';
@import './text/_font_color.scss';
@import './text/_font_size.scss';
@import './text/_special_font_properties.scss';

/* Standard supported sizes */
$fontSizes: (
  7: $font7,
  8: $font8,
  9: $font9,
  10: $font10,
  11: $font11,
  12: $font12,
  13: $font13,
  14: $font14,
  15: $font15,
  16: $font16,
  18: $font18,
  20: $font20,
  22: $font22,
  23: $font23,
  24: $font24,
  26: $font26,
  27: $font27,
  28: $font28,
  32: $font32,
  33: $font33,
  34: $font34,
  36: $font36,
  40: $font40,
  44: $font44,
  45: $font45,
  48: $font48,
  60: $font60,
);

@each $name, $size in $fontSizes {
  .text-#{$name} {
    font-size: $size;
  }
}

@media (min-width: $breakpoint-tablet-max) {
  @each $name, $size in $fontSizes {
    .d-text-#{$name} {
      font-size: $size;
    }
  }
}

@media (max-width: $breakpoint-tablet-max) {
  @each $name, $size in $fontSizes {
    .t-text-#{$name} {
      font-size: $size;
    }
  }
  .t-text-left {
    text-align: left;
  }
  .t-lineHeightNormal {
    line-height: normal;
  }
  .ttext-center,
  .t-text-center {
    text-align: center;
  }
}

@media (max-width: $breakpoint-mobile-max) {
  @each $name, $size in $fontSizes {
    .m-text-#{$name} {
      font-size: $size;
    }
  }
}

/* text-alignment */
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-justify {
  text-align: justify;
}

.lineHeightNormal {
  line-height: normal;
}

.lineHeightMedium {
  line-height: 1.43em;
}

.lineHeight18 {
  line-height: 18px;
}

.lineHeight16-8 {
  line-height: 16.8px;
}

.lineHeight20 {
  line-height: 20px;
}

.letterSpacing06 {
  letter-spacing: 0.6px;
}

.letterSpacing05 {
  letter-spacing: 0.5px;
}

.letterSpacing2 {
  letter-spacing: 2px;
}

.letterSpacing-m05 {
  letter-spacing: -0.5px;
}
.letterSpacing-m032 {
  letter-spacing: -0.32px;
}

/* Links */

a.cleanlink,
.cleanlink,
a.cleanlink:hover,
.cleanlink:hover {
  text-decoration: none;
  cursor: pointer;
}

/* Text transforms */
.toUpper {
  text-transform: uppercase;
}
.toTitleCase {
  text-transform: capitalize;
}
.toCapitalCase {
  text-transform: lowercase;
  &:first-letter {
    text-transform: uppercase;
  }
}

.valign-middle {
  vertical-align: middle;
}

.link-style {
  text-decoration: underline;
  color: $secondary_cta_bg_light;
  cursor: pointer;
}

.link-style-gray7 {
  text-decoration: underline;
  color: $gray7;
  cursor: pointer;
}

.no-line {
  text-decoration: none;
}
.text-underline {
  text-decoration: underline;
}

.line-through {
  text-decoration: line-through;
}

.no-pointer {
  cursor: default;
}

.pointer {
  cursor: pointer;
}

/* STD-Text formatting */
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

@media only screen and (max-width: 959px) {
  .mtext-left,
  .m-text-left {
    text-align: left;
  }
  .mtext-center,
  .m-text-center {
    text-align: center;
    text-align: -moz-center;
    text-align: -webkit-center;
  }
  .mtext-right,
  .m-text-right {
    text-align: right;
  }
}

@media (max-width: $breakpoint-tablet-max) {
  .t-letterSpacing-m01 {
    letter-spacing: -0.1px;
  }
  .t-letterSpacingNormal {
    letter-spacing: normal;
  }

  .t-text-justify {
    text-align: justify;
  }
}

@media (max-width: $breakpoint-mobile-max) {
  @each $name, $size in $fontSizes {
    .m-text-#{$name} {
      font-size: $size;
    }
  }
  .m-text-9 {
    font-size: 9px;
  }

  .m-bold {
    font-weight: 600;
  }

  .m-lineHeight24 {
    line-height: 24px;
  }

  .m-text-justify {
    text-align: justify;
  }
  .m-toUpper {
    text-transform: uppercase;
  }
  .m-letterSpacing05 {
    letter-spacing: 0.5px;
  }
}

.wordbreak-normal {
  word-break: normal;
}

.wordbreak-keep-all {
  word-break: keep-all;
}

.hyphens-none {
  hyphens: none;
}

.whitespace-normal {
  white-space: normal;
}
.whitespace-nowrap {
  white-space: nowrap;
}

.overflow-wrap-normal {
  overflow-wrap: normal;
}

.wordwrap-normal {
  word-wrap: normal;
}

.overflow-hidden {
  overflow: hidden;
}

.ellipsis {
  text-overflow: ellipsis;
}
