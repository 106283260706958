@import 'style/vars.module';

.Row {
  padding-right: 48px;
  padding-left: 48px;
  margin-bottom: 32px;

  &.margB0 {
    margin-bottom: 0px;
  }
  &.margB16 {
    margin-bottom: 16px;
  }
  &.padLR0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  &.personalInfoRow {
    margin-bottom: 16px;
  }

  &.padR0 {
    padding-right: 0px;
  }

  &.padL0 {
    padding-left: 0px;
  }

  &.offerCardRow {
    padding-right: $pad24;
    padding-left: $pad24;
    border-radius: $borderRadius8;
  }

  &.bannerTitle {
    padding: $pad0;
  }
  &.marketplace-landing-row {
    padding-left: $pad0;
    padding-right: $pad0;
  }

  &.collapseList {
    margin-bottom: 8px;

    .collapseListTitle {
      padding: $pad0 $pad16;
      margin-bottom: 0px;
    }

    .collapseListInner {
      padding: $pad0 $pad16;
      margin-bottom: 0px;
    }

    .collapseBarListInner {
      padding: $pad16 $pad16;
    }
  }

  &.carouselPremium {
    padding-right: 0px;
    padding-left: 84px;
  }

  &.autoLoanRow {
    margin-bottom: 12px;
  }

  &.carousel {
    padding-right: 0;
  }
  &.fullWidth {
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 0;
  }
  &.action {
    /*WIP*/
  }
  &.divider {
    height: 1px;
    background-color: $gray1;
    margin: 12px 24px;
  }
}
@media screen and (max-width: $breakpoint-tablet-max) {
  .Row {
    padding-right: 24px;
    padding-left: 24px;
    &.fullWidth {
      padding-right: 24px;
      padding-left: 24px;
    }
    &.fullWidthMobile.fullWidth {
      padding-right: 0;
      padding-left: 0;
    }

    &.carouselPremium {
      padding: 0px;
    }
    &.m-padLR0 {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

@media screen and (max-width: $breakpoint-mobile-max) {
  .Row {
    padding-right: 16px;
    padding-left: 16px;
    margin-bottom: 24px;
    &.offerCardRow {
      padding-right: $pad16;
      padding-left: $pad16;
    }
    &.bannerTitle {
      padding-left: $pad16;
      padding-right: $pad16;
    }
    &.carousel {
      padding-left: 8px;
    }
    &.collapseList {
      padding-right: 8px;
      padding-left: 8px;
      margin-bottom: 4px;
    }

    &.fullWidth {
      padding-right: 16px;
      padding-left: 16px;
      margin-bottom: 0;
    }
    &.noPadMobile {
      padding-left: 0px;
      padding-right: 0px;
    }

    &.mobilePad8 {
      padding-left: 8px;
      padding-right: 8px;
    }
    &.mobilePad16 {
      padding-right: $pad16;
      padding-left: $pad16;
    }
  }
}

.Row:last-child {
  margin-bottom: 0;
}
