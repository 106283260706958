@import 'style/vars.module';
.green300 {
  color: $green300;
}

.blue300 {
  color: $blue300;
}

.blue075 {
  color: $blue075;
}

.lBlue100 {
  color: $lBlue100;
}
.blueB100 {
  color: $blueB100;
}
.lBlue050 {
  color: $blue050;
}

.lBlue085 {
  color: $lBlue085;
}

.lBlue400 {
  color: $blueLB400;
}

.purple050 {
  color: $purple1;
}

.purple075 {
  color: $purple2;
}

.gray3 {
  color: $gray3;
}

.gray7 {
  color: $gray7;
}

.grayN250 {
  color: $grayN250;
}

.gray060 {
  color: $gray060;
}

.green100 {
  color: $green100;
}

.green050 {
  color: $green050;
}

.green025 {
  color: $green025;
}

.lgreen300 {
  color: $lgreen300;
}

.lgreen050 {
  color: $lgreen050;
}

.green075 {
  color: $green075;
}

.lgreen075 {
  color: $lgreen075;
}

.lgreen100 {
  color: $lgreen100;
}

.blue300 {
  color: $blue300;
}

.blue075 {
  color: $blue075;
}

.lBlue100 {
  color: $lBlue100;
}

.lBlue075 {
  color: $lBlue075;
}

.purple050 {
  color: $purple1;
}

.purple075 {
  color: $purple2;
}

.purple400 {
  color: $purple400;
}

.orange075 {
  color: $orange075;
}

.orangeO200 {
  color: $orangeO200;
}

.yellow050 {
  color: $yellow050;
}

.yellow075 {
  color: $yellow075;
}

.yellow025 {
  color: $yellow025;
}

.fontBlack {
  color: $black;
}
.fontBlack2 {
  color: $black2;
}
.fontRed025 {
  color: $red025;
}
.fontRed050 {
  color: $red050;
}
.fontRed075 {
  color: $red075;
}
.fontRed100 {
  color: $red100;
}
.fontRed200 {
  color: $red200;
}
.fontRed300 {
  color: $red300;
}
.fontRed400 {
  color: $red400;
}

.fontRedR300 {
  color: $redR300;
}

.fontPurple100 {
  color: $purple100;
}

.fontLBlue5 {
  color: $lBlue5;
}

.fontGray100 {
  color: $gray100;
}
.fontGray025 {
  color: $gray025;
}
.fontGray0 {
  color: $gray0;
}
.fontGray050 {
  color: $gray050;
}

.fontGray200 {
  color: $gray200;
}
.fontGray300 {
  color: $grayN300;
}
.fontGray250 {
  color: $neutral250;
}
.fontGrayN300 {
  color: $gray300;
}
.fontGray400 {
  color: $gray400;
}
.fontGray500 {
  color: $gray500;
}
.fontGray5 {
  color: $gray5;
}

.fontNeutral025 {
  color: $neutral025;
}
.fontNeutral075 {
  color: $neutral075;
}
.fontNeutral100 {
  color: $neutral100;
}
.fontNeutral300 {
  color: $neutral300;
}
.fontNeutral400 {
  color: $neutral400;
}
.fontNeutral450 {
  color: $neutral450;
}
.fontNeutral500 {
  color: $neutral500;
}
.fontNeutral800 {
  color: $neutral800;
}
.fontOrange100 {
  color: $orange100;
}
.fontBlue100 {
  color: $blue100;
}
.fontBlue200 {
  color: $blue200;
}
.fontBlue300 {
  color: $blue300;
}
.fontBlue400 {
  color: $blue400;
}
.fontBlueB400 {
  color: $blueB400;
}

.fontGreen400 {
  color: $green400;
}

.fontGreenG200 {
  color: $greenG200;
}
.fontGreenG400 {
  color: $greenG400;
}
.fontGreen200 {
  color: $green200;
}
.fontLgreen400 {
  color: $lgreen400;
}

.fontYellow400 {
  color: $yellow400;
}

.fontYellow100 {
  color: $yellow100;
}

.fontYellow3 {
  color: $yellow3;
}

.fontYellow200 {
  color: $yellow200;
}

.fontYellow400 {
  color: $yellow400;
}

.fontYellow500 {
  color: $yellow500;
}

.fontOrange400 {
  color: $orange400;
}

.fontWhite {
  color: $white;
}
.fontBlue3 {
  color: $blue3;
}

.fontGray4 {
  color: $gray4;
}
.fontGray6 {
  color: $gray6;
}
.fontGray8 {
  color: $gray8;
}

.fontGray075 {
  color: $gray075;
}

.fontLgreen7 {
  color: $lgreen7;
}
.fontGreen6 {
  color: $green6;
}
.fontGreen300 {
  color: $green300;
}
.fontYellow6 {
  color: $yellow6;
}
.fontYellow7 {
  color: $yellow7;
}
.fontRed4 {
  color: $red4;
}
.fontRed6 {
  color: $red6;
}
.fontRed100 {
  color: $red100;
}
.fontOrange6 {
  color: $orange6;
}
.fontNeutral600 {
  color: $neutral600;
}
.fontNeutral200 {
  color: $neutral200;
}
.fontNeutral100 {
  color: $neutral100;
}

.fontNeutral150 {
  color: $neutral150;
}

.fontNeutral425 {
  color: $neutral425;
}

.fontTransunionBlue {
  color: $transunionBlue;
}

@media (max-width: $breakpoint-tablet-max) {
  .t-fontGray300 {
    color: $gray300;
  }

  .t-fontGray400 {
    color: $gray400;
  }
}

.fontPrimaryCtaBgLight {
  color: $primary_cta_bg_light;
}
