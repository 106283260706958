@import 'style/vars.module';

.BodyContainer {
  width: 1020px;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  min-height: calc(100vh - 56px);

  &.fullWidthLayout {
    width: 100%;
  }

  &.signup {
    width: 100%;
    max-width: 960px;
    min-width: 320px;
    position: relative;
    min-height: calc(100vh - 56px);
  }
  &.simplistic {
    width: 100%;
    max-width: 469px;
    min-width: 320px;
    position: relative;
    min-height: calc(100vh - 56px);
  }
  &.cs-error {
    min-width: auto;
    &.isModal {
      width: auto;
    }
  }
}

.bodyContainer {
  display: flex;
  justify-content: center;
}

@media (max-width: $breakpoint-tablet-max) {
  .BodyContainer {
    width: 100%;
    max-width: 1020px;
    &.signup {
      max-width: 100%;
    }
    &.simplistic {
      max-width: 70%;
    }
    &.precheckout {
      min-width: 95%;
      width: 95%;
    }
  }
}

@media (max-width: $breakpoint-mobile-max) {
  .BodyContainer {
    min-width: 100%;
    &.signup {
      min-width: 320px;
      max-width: 100%;
    }
    &.simplistic {
      min-width: 320px;
      max-width: 100%;
    }
    &.precheckout {
      min-width: 95%;
      width: 95%;
    }
  }
}
