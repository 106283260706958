@import 'style/vars.module';
@import 'customBrandStylePath/_brand.scss';

.PremiumPillTag {
  &.navbarPill {
    background-color: $purple0;
    background-image: url('../../style/_assets/icons/premium_header.svg');
    background-position: 6px center;
    padding: 4px 12px 4px 25px;
  }
  &.profilePill {
    background-color: $purple0;
  }
}
