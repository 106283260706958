.MrphLink {
  &.router-link:focus-visible {
    outline: 2px solid #000000;
  }
  &.contrast {
    &.router-link:focus-visible {
      outline: 2px solid white;
    }
  }
}
