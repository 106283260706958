/* ==========================================================================
  Cognigy
========================================================================== */

[data-cognigy-webchat-root]
  [data-cognigy-webchat-toggle].webchat-toggle-button {
  @media (max-width: $breakpoint-mobile-max) {
    right: 16px;
    bottom: 70px;
  }
  right: 75px;
  bottom: 15px;
}
