@import 'style/vars.module';

.TitleContainer {
  margin-bottom: 32px;
  &.pageTitle {
    margin-top: 48px;
  }
  &.dashboardPageTitle {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  &.actionsTitle {
    display: flex;
    justify-content: space-between;
    a {
      white-space: nowrap;
    }
  }
  &.actionsTitleOverview {
    display: flex;
    align-items: baseline;
  }
  &.withDescription {
    margin-bottom: 24px;
  }
  > .SubTitle {
    margin-top: 8px;
  }
}

@media screen and (max-width: $breakpoint-tablet-max) {
  .TitleContainer {
    margin-bottom: 16px;
    &.pageTitle {
      margin-top: 16px;
    }
    &.largeBotMargin {
      margin-bottom: 28px;
    }
    &.actionsTitleOverview {
      justify-content: space-between;
    }
  }
}
