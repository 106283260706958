@import './_vars.module.scss';
@import 'customBrandStylePath/_brand.scss';

.cleaniframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.ytVideoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.horizontalScrollBar {
  flex-wrap: nowrap;
  overflow-x: auto;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }
}

.grayBgAnimation {
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  background: transparent;
}

.grayBgAnimation:hover {
  background: $neutral050;
}

.overflow-auto {
  overflow: auto;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.display-webkit-box {
  display: -webkit-box;
}

.chevronRightCss {
  width: 24px;
  height: 24px;
  border: solid 2px $neutral600;
  border-radius: 50%;

  &:before {
    width: 22px;
    content: '›';
    display: block;
    font-size: 24px;
    line-height: 16px;
    color: $neutral600;
    text-align: center;
  }
}

.stepsCheckMarkCss {
  width: 24px;
  height: 24px;
  background-color: $lgreen5;
  border-radius: 50%;

  &:before {
    content: '\2713';
    display: block;
    font-size: 16px;
    line-height: 24px;
    color: $white;
    text-align: center;
  }
}

$colors: (
  lBlue0: $lBlue0,
  lBlue1: $lBlue1,
  lBlue2: $lBlue2,
  lBlue3: $lBlue3,
  lBlue4: $lBlue4,
  lBlue5: $lBlue5,
  lBlue6: $lBlue6,
  lBlue025: $lBlue025,
  lBlue050: $lBlue050,
  lBlue075: $lBlue075,
  lBlue100: $lBlue100,
  lBlue200: $lBlue200,
  lBlue300: $lBlue300,
  lBlue400: $lBlue400,
  blue0: $blue0,
  blue1: $blue1,
  blue2: $blue2,
  blue3: $blue3,
  blue4: $blue4,
  blue5: $blue5,
  blue6: $blue6,
);
@each $name, $color in $colors {
  .highlight#{$name} {
    background-color: $color;
    font-size: $font18;
    transition-property: background-color font-size;
    transition-timing-function: ease-in-out;
  }

  .highlight {
    background-color: $lBlue0;
    font-size: 18px;
    transition-property: background-color font-size;
    transition-timing-function: ease-in-out;
  }

  .duration-4-seg {
    transition-duration: 4s;
  }
}

.zIndex1 {
  z-index: 1;
}

.opacity05 {
  opacity: 0.5;
}

.highLightRow {
  background: linear-gradient(90deg, #ffdc74, #fff0c4, #fff0c4, #ffdc74);
  background-size: 200% 200%;

  -webkit-animation: blink 2s ease infinite;
  -moz-animation: blink 2s ease infinite;
  animation: blink 2s ease infinite;
  animation: blink 2s ease infinite;
}

@keyframes blink {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.tooltipBlue {
  .ant-tooltip-arrow-content {
    &:before {
      background: $secondary_cta_bg_light !important;
    }
  }
  .ant-tooltip-inner {
    background-color: $secondary_cta_bg_light !important;
    border-radius: 8px;
    padding: 8px 25px;
  }
}

.bullet-filled {
  ul {
    list-style-type: disc;
  }
}
