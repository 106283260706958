/* STD-PAT tab navigation formatting */
.ant-tabs-bar {
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: $gray050;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-tabs-nav-list {
  .ant-tabs-ink-bar {
    background-color: $secondary_cta_bg_light;
  }
}
.ant-tabs-nav-scroll {
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &.ant-tabs-nav-scroll::-webkit-scrollbar {
    display: none;
  }
  .ant-tabs-ink-bar {
    background-color: $secondary_cta_bg_light;
  }
}
.ant-tabs-nav {
  .ant-tabs-tab {
    padding: 0;
    margin-right: 0px;
    font-size: 16px;
    & + .ant-tabs-tab {
      margin: 0;
    }
    .ant-tabs-tab-btn {
      padding: 12px 17px;
      display: block;
      width: 100%;
      color: $primary_light;
      &:focus-visible {
        border: solid 1px #000000;
      }
    }
    &.ant-tabs-tab-active {
      color: $secondary_cta_bg_light;
      @include font-bold;
      font-weight: bold;
      .ant-tabs-tab-btn {
        color: $secondary_cta_bg_light;
      }
    }
    &:hover {
      color: $secondary_cta_bg_light;
    }
    &:active {
      color: $gray300;
      background-color: $gray025;
    }
  }
}
.ant-tabs-tab-arrow-show {
  display: none;
}
.ant-tabs-nav-container-scrolling {
  padding-right: 0px;
  padding-left: 0px;
}

.collapse-custom {
  background-color: $white;
  .ant-collapse-item {
    border: none;
    margin-bottom: 10px;
    .ant-collapse-header {
      align-items: center;
      background-color: $gray005;
    }
    .ant-collapse-content {
      background-color: $white;
      border-top: 0;
      .ant-collapse-content-box {
        padding-top: 0;
      }
    }
  }
}

.panel-collapse-custom {
  .ant-collapse-content-box {
    padding: 0;
  }
}

.CarouselPanel {
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 15px 0 15px 0;
  }
}

.SubPanel {
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 4px 0 0 0;
  }
}

@media (min-width: $breakpoint-desktop-min) {
  .ant-tabs {
    &.fullWidthTabs {
      .ant-tabs-nav {
        display: flex;

        .ant-tabs-nav-wrap {
          width: 100%;
          flex-direction: column;
        }

        .ant-tabs-tab {
          flex-grow: 1;
          margin-right: 0px;
          width: 100%;
          text-align: center;
          justify-content: center;
        }
      }
    }
  }
}
.ant-tabs {
  &.whiteTabsHeader {
    .ant-tabs-nav {
      background-color: $system_neutral_n000;
    }
  }
  &.width100Tabs {
    .ant-tabs-nav {
      display: flex;
      margin-bottom: 0px;

      .ant-tabs-nav-wrap {
        width: 100%;
        flex-direction: column;
      }

      .ant-tabs-tab {
        flex-grow: 1;
        margin-right: 0px;
        width: 100%;
        text-align: center;
        justify-content: center;
      }
    }
  }
}
.ant-collapse {
  background-color: $white;
  &.account-list-header {
    border: 0;
    .ant-collapse-header {
      background-color: $white;
      border-bottom: solid 1px $neutral050;
    }
    .ant-collapse-item {
      border-bottom: 0;
    }
  }
}
.ant-radio {
  cursor: pointer;
  input[type='radio'],
  input[type='checkbox'] {
    cursor: pointer;
  }
}
