/** _grid **/

@import '_mixins';
@import 'vars.module';

@import './layout/_borders_and_shadow';
@import './layout/_box_backgrounds';
@import './layout/_fixed_percent';
@import './layout/_position';

.container {
  @include centerAlign();
  min-width: 320px;
  max-width: 960px;
  padding: 0;
}
.container.fullWidth {
  @include centerAlign();
  min-width: 320px;
  max-width: none;
}

.autoCenter {
  @include centerAlign();
}

$widths: 4, 5, 6, 8, 10, 12, 13, 15, 16, 17, 18, 20, 21, 22, 23, 24, 25, 26, 28,
  30, 31, 32, 33, 34, 35, 36, 38, 40, 44, 45, 46, 48, 49, 50, 51, 52, 53, 54, 55,
  56, 57, 58, 59, 60, 61, 62, 64, 65, 66, 68, 70, 72, 75, 78, 80, 82, 84, 85, 86,
  90, 94, 95, 100, 110, 116, 120, 125, 130, 135, 140, 150, 154, 160, 164, 172,
  180, 200, 204, 220, 240, 250, 258, 270, 300, 360, 396, 364, 404, 424, 780, 864;

@mixin formatWidth($percent) {
  width: $percent;
}

.heightAuto {
  height: auto;
}

.formatMaxContent {
  width: max-content;
}
.formatFitContent {
  width: fit-content;
}
@each $width in $widths {
  .format#{$width} {
    @include formatWidth($width * 1%);
  }
  .formatH#{$width} {
    height: $width * 1%;
  }

  .width#{$width}px {
    width: #{$width}px;
  }

  .height#{$width}px {
    height: #{$width}px;
  }
}
.width100 {
  width: 100%;
}

.height100 {
  height: 100;
}

.width288 {
  width: $fieldWidth;
}

.formatAuto {
  width: auto;
}

.desktopOnlyHide {
  display: none;
}

@media (min-width: $breakpoint-tablet-max) {
  .confine400 {
    @include centerAlign();
    max-width: 400px;
  }

  .t-formatFitContent {
    width: fit-content;
  }
  .dpoof {
    display: none;
  }
  .desktopHide {
    display: none;
  }
}

@for $image-size from 0 to 1000 {
  .width-#{$image-size}px {
    width: #{$image-size}px;
  }
  .height-#{$image-size}px {
    height: #{$image-size}px;
  }
  .widthHeight-#{$image-size}px {
    width: #{$image-size}px;
    height: #{$image-size}px;
  }
}

@media (max-width: $breakpoint-tablet-max) {
  @for $image-size from 0 to 1000 {
    .m-width-#{$image-size}px {
      width: #{$image-size}px;
    }
    .m-height-#{$image-size}px {
      height: #{$image-size}px;
    }
    .m-widthHeight-#{$image-size}px {
      width: #{$image-size}px;
      height: #{$image-size}px;
    }
  }
}

@for $line-size from 0 to 500 {
  .lineHeight-#{$line-size} {
    line-height: #{$line-size}px;
  }
}

@media (max-width: $breakpoint-tablet-max) {
  @for $line-size from 0 to 500 {
    .m-lineHeight-#{$line-size} {
      line-height: #{$line-size}px;
    }
  }
}

@media (max-width: $breakpoint-tablet-max) {
  .m-formatAuto {
    width: auto;
  }
  .m-HeightAuto {
    height: auto;
  }

  .container {
    @include centerAlign();
    min-width: 320px;
  }
  .t-heightInitial {
    height: initial;
  }

  .t-Width100 {
    width: 100%;
  }

  .tabletWidth100 {
    width: 100%;
  }

  .t-width100 {
    width: 100%;
  }

  /* deprecating mformat, use m-format*/
  @each $width in $widths {
    .mformat#{$width},
    .m-format#{$width} {
      @include formatWidth($width * 1%);
    }
    .m-formatH#{$width} {
      height: $width * 1%;
    }

    .m-width#{$width}px {
      width: #{$width}px;
    }

    .m-height#{$width}px {
      height: #{$width}px;
    }
  }
  .mconfine400 {
    @include centerAlign();
    max-width: 400px;
    float: none;
  }
  .m-formatFitContent {
    width: fit-content;
  }

  .mPoof {
    display: none !important;
  }

  .t-flex-row.flex-column {
    flex-direction: row;
  }

  .t-flex-align-center {
    align-items: center;
  }
}

@media (min-width: $breakpoint-tablet-max) {
  .d-width-50 {
    @include formatWidth(50%);
  }
}

@media (min-width: $breakpoint-tablet-min) and (max-width: $breakpoint-tablet-max) {
  @each $width in $widths {
    .t-format#{$width} {
      @include formatWidth($width * 1%);
    }
  }
}

/*PADDING*/

$fluff-widths: 0, 1, 2, 3, 4, 6, 7, 8, 10, 12, 14, 16, 18, 19, 20, 21, 22, 24,
  26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 48, 50, 52, 54, 56, 58, 60, 64, 68, 70,
  72, 73, 76, 80, 86, 90, 94, 96, 100, 102, 106, 112, 116, 120, 124, 128, 130,
  135, 150, 170, 180, 204, 312, 380, 400;

.margNL10 {
  margin-left: -10px;
}
.margNL12 {
  margin-left: -12px;
}
.margNL24 {
  margin-left: -24px;
}

.margNLR12 {
  margin-left: -12px;
  margin-right: -12px;
}
.margNLR16 {
  margin-left: -16px;
  margin-right: -16px;
}
.margNLR20 {
  margin-left: -20px;
  margin-right: -20px;
}
.margNLR24 {
  margin-left: -24px;
  margin-right: -24px;
}
.margNLR32 {
  margin-left: -32px;
  margin-right: -32px;
}

.desktopPadL0 {
  padding-left: 0 !important;
}

// odd margins
.margTop18 {
  margin-top: 18px;
}

.margTopN20 {
  margin-top: -20px;
}

.margTopN30 {
  margin-top: -30px;
}

.margTopNHideGrayHeaderSpace {
  margin-top: -56px;
}

.margTopN10 {
  margin-top: -10px;
}

.margTopN8 {
  margin-top: -8px;
}
.margTopN3 {
  margin-top: -3px;
}

.margTopN4 {
  margin-top: -4px;
}

.margLeft6 {
  margin-left: 6px;
}

.floatLeft {
  float: left;
}

.margBody {
  margin-left: 16px;
  margin-right: 16px;
}

@each $fluff-width in $fluff-widths {
  .pad#{$fluff-width} {
    padding: #{$fluff-width}px;
  }
  .padlr#{$fluff-width},
  .padLR#{$fluff-width} {
    padding-left: #{$fluff-width}px;
    padding-right: #{$fluff-width}px;
  }
  .padl#{$fluff-width},
  .padL#{$fluff-width} {
    padding-left: #{$fluff-width}px;
  }
  .padr#{$fluff-width},
  .padR#{$fluff-width} {
    padding-right: #{$fluff-width}px;
  }
  .padtb#{$fluff-width},
  .padTB#{$fluff-width} {
    padding-top: #{$fluff-width}px;
    padding-bottom: #{$fluff-width}px;
  }
  .padt#{$fluff-width},
  .padT#{$fluff-width} {
    padding-top: #{$fluff-width}px;
  }
  .padB#{$fluff-width},
  .padb#{$fluff-width} {
    padding-bottom: #{$fluff-width}px;
  }
  .marg#{$fluff-width} {
    margin: #{$fluff-width}px;
  }
  .marglr#{$fluff-width},
  .margLR#{$fluff-width} {
    margin-left: #{$fluff-width}px;
    margin-right: #{$fluff-width}px;
  }
  .margl#{$fluff-width},
  .margL#{$fluff-width} {
    margin-left: #{$fluff-width}px;
  }
  .margr#{$fluff-width},
  .margR#{$fluff-width} {
    margin-right: #{$fluff-width}px;
  }
  .margtb#{$fluff-width},
  .margTB#{$fluff-width} {
    margin-top: #{$fluff-width}px;
    margin-bottom: #{$fluff-width}px;
  }
  .margt#{$fluff-width},
  .margT#{$fluff-width} {
    margin-top: #{$fluff-width}px;
  }
  .margB#{$fluff-width},
  .margb#{$fluff-width} {
    margin-bottom: #{$fluff-width}px;
  }
  .borderRad#{$fluff-width},
  .borderrad#{$fluff-width} {
    border-radius: #{$fluff-width}px;
  }
  .gap-#{$fluff-width} {
    gap: #{$fluff-width}px;
  }
  .column-gap-#{$fluff-width} {
    column-gap: #{$fluff-width}px;
  }

  .row-gap-#{$fluff-width} {
    row-gap: #{$fluff-width}px;
  }
}

.margAuto {
  margin: auto;
}
.marglrAuto,
.margLRAuto {
  margin-left: auto;
  margin-right: auto;
}
.marglAuto,
.margLAuto {
  margin-left: auto;
}
.margrAuto,
.margRAuto {
  margin-right: auto;
}
.margtbAuto,
.margTBAuto {
  margin-top: auto;
  margin-bottom: auto;
}
.margtAuto,
.margTAuto {
  margin-top: auto;
}
.margBAuto,
.margbAuto {
  margin-bottom: auto;
}

@media (max-width: $breakpoint-mobile-max) {
  @each $fluff-width in $fluff-widths {
    .m-pad#{$fluff-width} {
      padding: #{$fluff-width}px;
    }
    .m-padlr#{$fluff-width},
    .m-padLR#{$fluff-width} {
      padding-left: #{$fluff-width}px;
      padding-right: #{$fluff-width}px;
    }
    .m-padl#{$fluff-width},
    .m-padL#{$fluff-width} {
      padding-left: #{$fluff-width}px;
    }
    .m-padr#{$fluff-width},
    .m-padR#{$fluff-width} {
      padding-right: #{$fluff-width}px;
    }
    .m-padtb#{$fluff-width},
    .m-padTB#{$fluff-width} {
      padding-top: #{$fluff-width}px;
      padding-bottom: #{$fluff-width}px;
    }
    .m-padt#{$fluff-width},
    .m-padT#{$fluff-width} {
      padding-top: #{$fluff-width}px;
    }
    .m-padB#{$fluff-width},
    .m-padb#{$fluff-width} {
      padding-bottom: #{$fluff-width}px;
    }
    .m-marg#{$fluff-width} {
      margin: #{$fluff-width}px;
    }
    .m-marglr#{$fluff-width},
    .m-margLR#{$fluff-width} {
      margin-left: #{$fluff-width}px;
      margin-right: #{$fluff-width}px;
    }
    .m-margl#{$fluff-width},
    .m-margL#{$fluff-width} {
      margin-left: #{$fluff-width}px;
    }
    .m-margr#{$fluff-width},
    .m-margR#{$fluff-width} {
      margin-right: #{$fluff-width}px;
    }
    .m-margtb#{$fluff-width},
    .m-margTB#{$fluff-width} {
      margin-top: #{$fluff-width}px;
      margin-bottom: #{$fluff-width}px;
    }
    .m-margt#{$fluff-width},
    .m-margT#{$fluff-width} {
      margin-top: #{$fluff-width}px;
    }
    .m-margB#{$fluff-width},
    .m-margb#{$fluff-width} {
      margin-bottom: #{$fluff-width}px;
    }
    .m-gap-#{$fluff-width} {
      gap: #{$fluff-width}px;
    }
    .m-column-gap-#{$fluff-width} {
      column-gap: #{$fluff-width}px;
    }
    .m-row-gap-#{$fluff-width} {
      row-gap: #{$fluff-width}px;
    }
  }

  .m-margAuto {
    margin: auto;
  }
  .m-marglrAuto,
  .m-margLRAuto {
    margin-left: auto;
    margin-right: auto;
  }
  .m-marglAuto,
  .m-margLAuto {
    margin-left: auto;
  }
  .m-margrAuto,
  .m-margRAuto {
    margin-right: auto;
  }
  .m-margtbAuto,
  .m-margTBAuto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .m-margtAuto,
  .m-margTAuto {
    margin-top: auto;
  }
  .m-margBAuto,
  .m-margbAuto {
    margin-bottom: auto;
  }

  .m-padL8 {
    padding-left: 8px !important;
  }

  .mobilePadR0 {
    padding-right: 0;
  }

  .m-killPad {
    padding: 0;
  }

  .desktopPadL0 {
    padding-left: unset;
  }
  .mobilePadL24 {
    padding-left: 24px !important;
  }
}

/** POSITIONING **/
.fclear {
  clear: both;
}

.fright {
  float: right;
}

.fleft {
  float: left;
}

.fcenter {
  float: none;
  margin-left: auto;
  margin-right: auto;
}

.clearfix {
  clear: both;
}

.inblock {
  display: inline-block;
}

.noShrink {
  flex-shrink: 0;
}

.pos-sticky {
  position: -webkit-sticky;
  position: sticky;
}

.abs-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.FlexContainer,
.flex-container {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -o-flex;
  display: flex;

  &.flex-row {
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
  }

  &.flex-row.noWrap {
    flex-direction: row;
    flex-wrap: nowrap;
  }
  &.wrap {
    flex-wrap: wrap;
  }

  &.flex-column {
    flex-flow: column;
  }

  &.mobileCol-desktopRow {
    flex-flow: row;
  }

  &.justify-around {
    justify-content: space-around;
  }

  &.justify-between {
    justify-content: space-between;
  }

  &.justify-evenly {
    justify-content: space-evenly;
  }

  &.justify-center {
    justify-content: center;
  }

  &.justify-end {
    justify-content: flex-end;
  }

  &.justify-start {
    justify-content: flex-start;
  }

  &.text-align-justify {
    text-align: justify;
  }

  &.content-align-between {
    align-content: space-between;
  }

  &.content-align-around {
    align-content: space-around;
  }

  &.content-align-center {
    align-content: center;
  }

  &.content-align-start {
    align-content: flex-start;
  }

  &.content-align-end {
    align-content: flex-end;
  }

  &.items-align-center {
    align-items: center;
  }

  &.items-align-end {
    align-items: flex-end;
  }

  &.items-align-start {
    align-items: flex-start;
  }

  &.align-baseline {
    align-items: baseline;
  }

  > .align-self-center {
    align-self: center;
  }

  > .align-self-end {
    align-self: flex-end;
  }

  > .align-self-start {
    align-self: flex-start;
  }

  > .justify-self-end {
    justify-self: flex-end;
  }

  @media (max-width: $breakpoint-tablet-max) {
    &.flex-row.tabletWrap {
      flex-wrap: wrap;
    }
    &.t-flex-column {
      flex-flow: column;
    }
    &.flex-row.t-flex-column {
      flex-direction: column;
    }
    &.tablet-justify-normal {
      justify-content: normal;
    }
    &.tablet-justify-center {
      justify-content: center;
    }
    &.tablet-space-around {
      justify-content: space-around;
    }
    &.tablet-justify-start {
      justify-content: flex-start;
    }
    &.tablet-justify-end {
      justify-content: flex-end;
    }
    &.tablet-space-between {
      justify-content: space-between;
    }
    &.tablet-space-evenly {
      justify-content: space-evenly;
    }
    &.tablet-align-items-auto {
      align-items: auto;
    }
    &.tablet-align-items-center {
      align-items: center;
    }
    &.flex-tablet-col {
      flex-direction: column !important;
    }
    &.flex-tablet-row {
      flex-direction: row !important;
    }
    &.tablet-wrap-reverse {
      flex-wrap: wrap-reverse;
    }
  }
  @media (max-width: $breakpoint-mobile-max) {
    &.flex-row.mobileWrap {
      flex-wrap: wrap;
    }
    &.mobile-wrap-reverse {
      flex-wrap: wrap-reverse;
    }
    &.mobileCol-desktopRow {
      flex-flow: column;
    }
    &.flex-mobile-col {
      flex-direction: column !important;
    }
    &.flex-mobile-row {
      flex-direction: row !important;
    }
    &.mobile-justify-center {
      justify-content: center;
    }
    &.mobile-justify-end {
      justify-content: flex-end;
    }
    &.mobile-items-align-start {
      align-items: flex-start;
    }
    > .mobile-align-self-initial {
      align-self: initial;
    }
    &.mobile-align-self-center {
      align-self: center;
    }
  }
}

.flex-rowWrap {
  /* Direction defaults to 'row', so not really necessary to specify */
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  flex-flow: row wrap;
  flex-direction: row;
  flex-wrap: wrap;
}

.inflex-container {
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: -o-inline-flex;
  display: inline-flex;
}
.flex-container {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -o-flex;
  display: flex;
}
/* Flex Stuff */
.inflex-container,
.flex-container {
  &.flex-row-nowrap {
    -webkit-flex-flow: row;
    flex-flow: row;
  }
  &.flex-wrap {
    flex-flow: wrap;
  }
  &.flex-row {
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  &.flex-column {
    flex-flow: column;
  }
  &.justify-around {
    justify-content: space-around;
  }
  &.justify-between {
    justify-content: space-between;
  }
  &.justify-center {
    justify-content: center;
  }
  &.justify-end {
    justify-content: flex-end;
  }
  &.justify-start {
    justify-content: flex-start;
  }
  &.content-align-between {
    align-content: space-between;
  }
  &.content-align-around {
    align-content: space-around;
  }
  &.content-align-center {
    align-content: center;
  }
  &.content-align-start {
    align-content: flex-start;
  }
  &.content-align-end {
    align-content: flex-end;
  }
  &.items-align-start {
    align-items: start;
  }
  &.items-align-center {
    align-items: center;
  }
  &.items-align-baseline {
    align-items: baseline;
  }
  &.align-items-center {
    align-items: center;
  }
  &.align-items-stretch {
    align-items: stretch;
  }
  &.align-items-start {
    align-items: start;
  }

  &.align-items-end {
    align-items: end;
  }
  &.align-content-center {
    align-content: center;
  }
}

$flex-ord: 1, 2, 3, 4, 5;

@each $o in $flex-ord {
  .flex-ord-#{$o} {
    order: $o;
  }
}

.flex-1 {
  flex: 1;
}

.flex-025 {
  width: 24%;
}

.flex-basis-100 {
  flex-basis: 100%;
}

.flex-basis-80 {
  flex-basis: 80%;
}

.flex-basis-50 {
  flex-basis: 50%;
}

@media (min-width: $breakpoint-tablet-max) {
  .inflex-container,
  .flex-container {
    &.d-flex-column {
      flex-flow: column;
    }
    &.d-flex-row {
      flex-flow: row;
    }
  }

  @each $o in $flex-ord {
    .d-flex-ord-#{$o} {
      order: $o;
    }
  }
}

@media (max-width: $breakpoint-tablet-max) {
  .inflex-container,
  .flex-container {
    &.m-flex-column {
      flex-flow: column;
    }
    &.m-flex-row {
      flex-flow: row;
    }
    &.m-justify-start {
      justify-content: flex-start;
    }
    &.m-justify-normal {
      justify-content: normal;
    }
    &.m-justify-between {
      justify-content: space-between;
    }
    &.m-justify-center {
      justify-content: center;
    }
    &.mobile-justify-center {
      justify-content: center;
    }
    &.m-items-align-center {
      align-items: center;
    }
    &.m-items-align-start {
      align-items: flex-start;
    }
    &.m-items-align-unset {
      align-items: unset;
    }
    &.m-flex-wrap {
      flex-flow: wrap;
    }
    &.m-flex-direction-column-reverse {
      flex-direction: column-reverse;
    }
  }

  @each $o in $flex-ord {
    .m-flex-ord-#{$o} {
      order: $o;
    }
  }
}

.vcenter {
  vertical-align: middle;
}

.bgPremiumGradient {
  background-image: radial-gradient(
    circle at 100% 0,
    $purple200,
    $purple400 100%
  );
  color: $white;
}

.centerImage {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  align-content: center;
}

.inline {
  display: inline;
}

.inline-grid {
  display: inline-grid;
}

.block {
  display: block;
}

.hide {
  display: none;
}

.contents {
  display: contents;
}

.grid {
  display: grid;
}

.invisible-pixel {
  height: 1px;
  width: 1px;
}

.height-1px {
  height: 1px;
}

.height-80px {
  height: 80px;
}

@media (max-width: $breakpoint-mobile-max) {
  .inflex-container,
  .flex-container {
    .m-flex-wrap {
      flex-flow: wrap;
    }
    .mob-flex-column,
    &.mob-flex-column {
      flex-flow: column;
    }
    &.mobile-items-align-start,
    &.m-items-align-start {
      align-items: flex-start;
    }
    &.mob-justify-between {
      justify-content: space-between;
    }
  }
}

.hide {
  display: none;
}

@media (min-width: $breakpoint-tablet-max) {
  .d-hide {
    display: none;
  }
}

@media (min-width: $breakpoint-mobile-max) {
  .desktop-tablet-hide {
    display: none;
  }
}

@media (max-width: $breakpoint-tablet-max) {
  .t-hide {
    display: none;
  }
  .t-pos-abs {
    position: absolute;
  }
  .t-pos-rel {
    position: relative;
  }
  .t-grid {
    display: grid;
  }
  .t-block {
    display: block;
  }

  .t-center-xy {
    display: grid;
    justify-content: center;
    align-content: center;
  }
  .t-pos-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

@media (max-width: $breakpoint-mobile-max) {
  .m-hide {
    display: none;
  }
  .m-block {
    display: block;
  }
  .m-grid {
    display: grid;
  }
  .m-inline-grid {
    display: inline-grid;
  }
  .m-pos-sticky {
    position: -webkit-sticky;
    position: sticky;
  }

  .m-margTopN20 {
    margin-top: -20px;
  }
  .m-fright {
    float: right;
  }
}

.center-xy {
  display: grid;
  justify-content: center;
  align-content: center;
}
@import './layout/responsive_hide';
