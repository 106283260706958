@import 'style/vars.module';

.main-menu {
  position: fixed;
  z-index: 3;
  height: 100%;

  a {
    color: black;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  a:visited {
    color: black;
  }
}

.plusUserMenu {
  color: $white0 !important;
  background-color: $secondary_light !important;
}

.ant-menu {
  height: 100%;
}

.ant-menu-item {
  display: flex;
  align-items: center;
  height: 48px;
  width: 100%;

  .plusNavLabel {
    color: $white0;
  }

  a link {
    color: black;
  }

  &.no-link {
    padding-left: 0;

    :hover {
      color: $blue100;
    }

    .menuButton {
      font-size: $font24;
    }

    .menuButton.filled {
      display: none;
    }

    .menuButton.outline {
      display: block;

      svg {
        rect {
          fill: none;
        }

        path {
          stroke: none;
          fill: currentColor;
        }
      }
    }
  }

  &.selected {
    color: $blue100;

    .menuButton.filled {
      display: block;
      margin-left: 0;

      svg {
        rect {
          fill: none;
        }

        path {
          stroke: currentColor;
          fill: currentColor;
        }
      }
    }

    .menuButton.outline {
      display: none;
    }

    .Body-1 {
      color: $lBlue085;
      font-weight: bold;
    }
  }
}

.stayLimited {
  text-decoration: none;
  color: $black;
}

.stayLimitedPlus {
  text-decoration: none;
  color: $white0;
}

.ant-menu-item:not(.subMenuItem) {
  .hightlightFeature {
    position: absolute;
    top: 15px;
    right: 16px;
  }
}

.ant-menu-submenu-item {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}

.ant-menu-submenu-title {
  flex-direction: row;
  display: flex;
  align-items: center;
}

.item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
}

.hamburgerTitle {
  display: none;
}

@media (min-width: 288px) and (max-width: 599px) {
  .Hamburger {
    display: flex;
  }

  .hamburgerTitle {
    display: flex;
    font-size: $font10;
  }
}

//fixed below
@media (max-width: $breakpoint-tablet-max) {
  .main-menu {
    width: 244px;
    height: 100vh;
    /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    top: 0px;
    z-index: 6;
  }
}

@media (max-width: 359px) {
  .cslogo-msmall {
    display: block;
  }

  .cslogo-default {
    display: none;
  }

  .cslogoPremium-msmall {
    display: block;
  }

  .cslogoPremium-default {
    display: none;
  }

  .cslogoPlus-msmall {
    display: block;
  }

  .cslogoPlus-default {
    display: none;
  }

  .main-main-container .main-nav-container .navbar-logo {
    width: auto;
  }

  .main-main-container .main-nav-container .premium-logo {
    width: auto;
    margin: 0;
    padding: 0;

    svg {
      height: 100%;
    }
  }

  .main-main-container .main-nav-container .plus-logo {
    width: auto;
    margin: 0;
    padding: 0;

    svg {
      width: 70px;
      padding-left: 10px;
      margin: 0;
    }
  }
}

@media (min-width: 360px) {
  .cslogo-msmall {
    display: none;
  }

  .cslogoPremium-msmall {
    display: none;
  }

  .cslogoPlus-msmall {
    display: none;
  }
}
