@import 'style/vars.module';
@import 'style/system_colors.scss';
@import 'customBrandStylePath/_brand.scss';

.mrphbutton {
  &.STDpat01 {
    font-weight: bold;
    border-color: transparent;
    border-width: 2px;
    border-style: solid;
    height: auto;
    box-sizing: border-box;
    position: relative;
    border-radius: 4px;
    &.border-rad100px {
      border-radius: $borderRadius100;
    }
    &.notBold {
      font-weight: normal;
    }

    & .anticon {
      position: relative;
      top: -3px;
    }

    &:focus-visible {
      outline: 2px solid #000000;
    }
  }
  &:not([disabled]) {
    &.STDpat01.primary {
      // primary
      color: $primary_cta_text_light;
      background-color: $primary_cta_bg_light;
      border-color: $primary_cta_bg_light;

      &:hover {
        color: $primary_cta_text_light;
        background-color: darken($primary_cta_bg_light, 10%);
        border-color: darken($primary_cta_bg_light, 10%);
      }

      &:focus {
        color: $primary_cta_text_light;
        background-color: $primary_cta_bg_light;
        border-color: $primary_cta_bg_light;
        box-shadow: 0 0 1px 3px lighten($primary_cta_bg_light, 42%);
      }

      &:active {
        color: $primary_cta_text_light;
        background-color: darken($primary_cta_bg_light, 15%);
        border-color: darken($primary_cta_bg_light, 15%);
        box-shadow: 0 0 0 0px $white;
      }

      &.ant-btn-loading {
        color: $gray100;
        background-color: $gray005;
        border-color: transparent;
        box-shadow: 0 0 0 0px $white;
      }
    }

    &.STDpat01.secondary {
      // primary
      color: $secondary_cta_text_light;
      background-color: $secondary_cta_bg_light;
      border-color: $secondary_cta_bg_light;

      &:hover {
        color: $secondary_cta_text_light;
        background-color: darken($secondary_cta_bg_light, 10%);
        border-color: darken($secondary_cta_bg_light, 10%);
      }

      &:focus {
        color: $secondary_cta_text_light;
        background-color: $secondary_cta_bg_light;
        border-color: $secondary_cta_bg_light;
        box-shadow: 0 0 1px 3px lighten($secondary_cta_bg_light, 42%);
      }

      &:active {
        color: $secondary_cta_text_light;
        background-color: darken($secondary_cta_bg_light, 15%);
        border-color: darken($secondary_cta_bg_light, 15%);
        box-shadow: 0 0 0 0px $white;
      }

      &.ant-btn-loading {
        color: $gray100;
        background-color: $gray005;
        border-color: transparent;
        box-shadow: 0 0 0 0px $white;
      }
    }

    &.STDpat01.primaryLink {
      // primary
      color: $primary_cta_bg_light;
      background-color: transparent;
      border-color: transparent;

      &:hover {
        color: $primary_cta_bg_light;
        background-color: transparent;
        border-color: transparent;
      }

      &:focus {
        color: $primary_cta_bg_light;
        background-color: transparent;
        border-color: transparent;
        box-shadow: 0 0 1px 3px lighten($primary_cta_text_light, 42%);
      }

      &:active {
        color: $primary_cta_bg_light;
        background-color: transparent;
        border-color: transparent;
        box-shadow: 0 0 0 0px $system_neutral_n000;
      }

      &.ant-btn-loading {
        color: $gray100;
        background-color: $gray005;
        border-color: transparent;
        box-shadow: 0 0 0 0px $system_neutral_n000;
      }
    }

    &.STDpat01.standard,
    &.STDpat01.standardFilled {
      // standard, standarFilled
      color: $secondary_cta_bg_light;
      background-color: transparent;
      border-color: $secondary_cta_bg_light;

      &.borderBlue100 {
        border-color: $secondary_cta_bg_light;
      }

      &:hover {
        color: $secondary_cta_bg_light;
        background-color: transparent;
        border-color: $secondary_cta_bg_light;
      }

      &:focus {
        color: $secondary_cta_bg_light;
        background-color: transparent;
        border-color: $secondary_cta_bg_light;
        box-shadow: 0 0 1px 3px lighten($secondary_cta_bg_light, 42%);
      }

      &:active {
        color: $secondary_cta_bg_light;
        background-color: $blue050;
        border-color: $secondary_cta_bg_light;
        box-shadow: 0 0 0 0px $white;
      }

      &.ant-btn-loading {
        color: $blue100;
        background-color: $gray005;
        border-color: $blue100;
        box-shadow: 0 0 0 0px $white;
      }
    }

    &.STDpat01.standardBlue,
    &.STDpat01.standardBlue {
      // standard, standarFilled
      color: $secondary_cta_bg_light;
      background-color: transparent;
      border-color: $secondary_cta_bg_light;

      &:hover {
        color: $secondary_cta_bg_light;
        background-color: $gray005;
        border-color: $secondary_cta_bg_light;
      }

      &:focus {
        color: $secondary_cta_bg_light;
        background-color: $white;
        border-color: $secondary_cta_bg_light;
        box-shadow: 0 0 1px 3px $gray025;
      }

      &:active {
        color: $secondary_cta_bg_light;
        background-color: $gray025;
        border-color: $gray200;
        box-shadow: 0 0 0 0px $white;
      }

      &.ant-btn-loading {
        color: $secondary_cta_bg_light;
        background-color: $gray005;
        border-color: $gray050;
        box-shadow: 0 0 0 0px $white;
      }
    }

    &.STDpat01.standardWhite {
      // standard, standarFilled
      color: $system_neutral_n000;
      background-color: transparent;
      border-color: $system_neutral_n000;

      &:hover {
        color: $system_neutral_n000;
        background-color: rgba($color: $system_neutral_n000, $alpha: 0.2);
        border-color: $system_neutral_n000;
      }

      &:focus {
        color: $system_neutral_n000;
        background-color: rgba($color: $system_neutral_n000, $alpha: 0.2);
        border-color: $system_neutral_n000;
        box-shadow: 0 0 1px 3px $neutral025;
      }

      &:active {
        color: $system_neutral_n000;
        background-color: rgba($color: $system_neutral_n000, $alpha: 0.2);
        border-color: $system_neutral_n000;
        box-shadow: 0 0 0 0px $white;
      }

      &.ant-btn-loading {
        color: $system_neutral_n000;
        background-color: rgba($color: $system_neutral_n000, $alpha: 0.2);
        border-color: $system_neutral_n000;
        box-shadow: 0 0 0 0px $white;
      }
    }

    &.STDpat01.standardFilled {
      //standarFilled
      border-style: none;
      &:focus {
        box-shadow: 0 0 1px 3px $blue075;
      }

      &:active {
        box-shadow: 0 0 0px 0px $white;
      }
    }

    &.STDpat01.destructive {
      // destructive
      color: $primary_cta_text_light;
      background-color: $system_error_e200;
      border-color: $system_error_e200;

      &:hover {
        color: $primary_cta_text_light;
        background-color: $system_error_e200;
        border-color: $system_error_e200;
      }

      &:focus {
        color: $white;
        background-color: $red100;
        border-color: $red100;
        box-shadow: 0 0 1px 3px $red025;
      }

      &:active {
        color: $white;
        background-color: $red300;
        border-color: $red300;
        box-shadow: 0 0 0 0px $white;
      }

      &.ant-btn-loading {
        color: $gray100;
        background-color: $gray005;
        border-color: transparent;
        box-shadow: 0 0 0 0px $white;
      }
    }

    &.STDpat01.destructive-link {
      // destructive
      color: $system_error_e200;
      background-color: transparent;
      border-color: transparent;

      &:hover {
        color: $red300;
        background-color: transparent;
        border-color: transparent;
      }

      &:focus {
        color: $red300;
        background-color: transparent;
        border-color: transparent;
        box-shadow: 0 0 1px 3px $red025;
      }

      &:active {
        color: $red300;
        background-color: transparent;
        border-color: transparent;
        box-shadow: 0 0 0 0px $white;
      }

      &.ant-btn-loading {
        color: $gray100;
        background-color: $gray005;
        border-color: transparent;
        box-shadow: 0 0 0 0px $white;
      }
    }

    &.STDpat01.link {
      // link
      color: $secondary_cta_bg_light;
      background-color: transparent;
      border-color: transparent;

      &:hover {
        color: darken($secondary_cta_bg_light, 10%);
        background-color: transparent;
        border-color: transparent;
      }

      &:focus {
        color: $blue200;
        background-color: transparent;
        border-color: transparent;
        box-shadow: 0 0 1px 3px $lBlue0;
      }

      &:active {
        color: $blue300;
        background-color: transparent;
        border-color: transparent;
        box-shadow: 0 0 0 0px $white;
      }
    }

    &.STDpat01.linkbutton {
      // linkbutton
      color: $secondary_cta_bg_light;
      background-color: transparent;
      border-color: transparent;
      @media (hover: hover) {
        &:hover {
          color: $secondary_cta_bg_light !important;
          background-color: transparent;
          border-color: transparent;
          &:before {
            position: absolute;
            top: 26px;
            margin: 0px 16px;
            content: '';
            display: block;
            height: 2px;
            background-color: $secondary_cta_bg_light !important;
            opacity: 1;
          }
        }

        &:focus {
          color: $blue200 !important;
          background-color: transparent;
          border-color: transparent;
          box-shadow: 0 0 1px 3px $lBlue0;
          &:before {
            position: absolute;
            top: 26px;
            margin: 0px 16px;
            content: '';
            display: block;
            height: 2px;
            background-color: $blue200 !important;
            opacity: 1;
          }
        }

        &:active {
          color: $blue300 !important;
          background-color: transparent;
          border-color: transparent;
          box-shadow: 0 0 0 0px $white;
          &:before {
            position: absolute;
            top: 26px;
            margin: 0px 16px;
            content: '';
            display: block;
            height: 2px;
            background-color: $blue300 !important;
            opacity: 1;
          }
        }
      }

      &.ant-btn-loading {
        color: $gray100;
        background-color: $gray005;
        border-color: transparent;
        box-shadow: 0 0 0 0px $white;
      }
    }

    &.STDpat01:focus {
      &.hideFocusShadow {
        box-shadow: none;
      }
    }

    &.STDpat01.yellowButton {
      // yellowButton
      color: $neutral400;
      background-color: $yellow100;
      border-color: transparent;

      &:hover {
        color: $neutral400;
        background-color: $yellow100;
        border-color: transparent;
      }

      &:focus {
        color: $neutral400;
        background-color: $yellow100;
        border-color: transparent;
      }

      &:active {
        color: $neutral400;
        background-color: $yellow100;
        border-color: transparent;
        box-shadow: 0 0 0 0px $white;
      }

      &.ant-btn-loading {
        color: $gray100;
        background-color: $gray005;
        border-color: transparent;
        box-shadow: 0 0 0 0px $white;
      }
    }
    &.STDpat01.blackButton {
      // blackbutton
      color: $white;
      background-color: $neutral400;
      border-color: transparent;

      &:hover {
        color: $white;
        background-color: $neutral400;
        border-color: transparent;
      }

      &:focus {
        color: $white;
        background-color: $neutral400;
        border-color: transparent;
      }

      &:active {
        color: $white;
        background-color: $neutral400;
        border-color: transparent;
        box-shadow: 0 0 0 0px $white;
      }

      &.ant-btn-loading {
        color: $gray100;
        background-color: $gray005;
        border-color: transparent;
        box-shadow: 0 0 0 0px $white;
      }
    }
    &.STDpat01.standardGray {
      color: $system_neutral_n100;
      background-color: transparent;
      border-color: $system_neutral_n075;
    }

    &.STDpat01.primaryLight {
      // primary
      color: $black0;
      background-color: $primary_cta_bg_dark;
      border-color: $primary_cta_bg_dark;

      &:hover {
        color: $black0;
        background-color: darken($primary_cta_bg_dark, 10%);
        border-color: darken($primary_cta_bg_dark, 10%);
      }

      &:focus {
        color: $black0;
        background-color: $primary_cta_bg_dark;
        border-color: $primary_cta_bg_dark;
        box-shadow: 0 0 1px 3px lighten($primary_cta_bg_dark, 42%);
      }

      &:active {
        color: $black0;
        background-color: darken($primary_cta_bg_dark, 15%);
        border-color: darken($primary_cta_bg_dark, 15%);
        box-shadow: 0 0 0 0px $white;
      }

      &.ant-btn-loading {
        color: $gray100;
        background-color: $gray005;
        border-color: transparent;
        box-shadow: 0 0 0 0px $white;
      }
    }
  }

  &[disabled] {
    color: $system_neutral_n250;
    background-color: $system_neutral_n025;
    border-color: $system_neutral_n025;

    &:hover {
      color: $system_neutral_n250;
      background-color: $system_neutral_n025;
      border-color: $system_neutral_n025;
    }

    &.STDpat01.standard {
      // standard disabled
      color: $gray050;
      background-color: transparent;
      border-color: $gray050;

      &:hover {
        color: $gray050;
        background-color: transparent;
        border-color: $gray050;
      }

      &:focus {
        color: $gray050;
        background-color: transparent;
        border-color: $gray050;
        box-shadow: 0 0 0px 0px $white;
      }

      &:active {
        color: $gray050;
        background-color: transparent;
        border-color: $gray050;
        box-shadow: 0 0 0 0px $white;
      }
    }
    &.STDpat01.blackButton {
      border-color: $neutral075;
      background-color: $neutral075;
      color: $neutral150;
    }
    &.STDpat01.link {
      color: $gray050;
      background-color: $white;
      border: none;
    }
  }

  &.fullWidth {
    width: 100%;
  }
}

@media (max-width: $breakpoint-tablet-max) {
  .mrphbutton {
    &:not(.link):not(.neverFullWidth) {
      width: 100%;
    }
  }
}
