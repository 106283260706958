@import 'style/vars.module';

.skip-to-content-link {
  left: -9999px;
  top: 3%;
  z-index: 999;
  opacity: 0;
}

.skip-to-content-link:focus {
  left: 7%;
  transform: translateX(-50%);
  opacity: 1;
}
