@import 'style/vars.module';
@import 'style/system_colors';

/* signup.css*/
@keyframes social_ticker_dots {
  0%,
  20% {
    content: '.';
  }
  40% {
    content: '..';
  }
  60% {
    content: '...';
  }
  90%,
  100% {
    content: '';
  }
}

.signup {
  .footer-container {
    position: absolute;
    bottom: 0;
    padding-bottom: 8px;
    width: 100%;
  }
  .monthContainer {
    width: 100%;
    @media (max-width: $breakpoint-mobile-max) {
      width: 132%;
    }
  }
  .ant-checkbox-input {
    cursor: pointer;
  }
  .floating-label.drop-down-select {
    width: 100%;
  }
  .floating-label {
    width: 100%;
    &:not(.passwordValidation):not(.noMarginTop) {
      margin-top: 16px;
    }
    @media (max-width: $breakpoint-mobile-max) {
      .veriticalLine {
        right: 32px;
      }
    }
  }
  .ant-select {
    width: 100%;
  }
  .name-fields .floating-label,
  .summary-address .current-form-container .floating-label {
    margin-top: 8px;
  }

  input::-webkit-calendar-picker-indicator {
    display: none;
  }
  input[type='date']::-webkit-input-placeholder {
    visibility: hidden !important;
  }
  .SSNField {
    > span,
    i {
      margin-top: 16px;
    }
    > .text-box {
      width: 175px;
    }

    &.fullSsn > .text-box {
      width: 100%;
    }
    @media (max-width: $breakpoint-mobile-max) {
      > .text-box {
        width: 100%;
      }
    }
  }

  &.loading-container {
    margin-right: auto;
    margin-left: auto;
  }

  .current-form-container,
  .prev-container {
    position: relative;
  }
  .radio-label {
    padding-left: 8px;
    padding-top: 8px;
    cursor: pointer;
    @media (max-width: $breakpoint-mobile-max) {
      padding-left: 4px;
    }
  }

  .secondary-text {
    color: $gray3;
    font-size: 14px;
  }

  .signup1-mid {
    margin-left: auto;
    margin-right: auto;
  }

  .answer-container {
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    align-items: center;
  }

  /* Signup 2 */
  .duplicate-error,
  .block-error {
    background-color: $red0;
    border-radius: 4px;
    border: 1px solid $red6;
    padding: 16px;
    font-size: 14px;
    margin-left: auto;
    margin-right: auto;
  }

  .block-error a,
  .block-error a:hover,
  .block-error a:active {
    text-decoration: underline !important;
    font-weight: 600;
  }

  .duplicate-error {
    margin: 16px 0px 16px 0px;
  }

  .autocomplete-icon {
    margin-right: 2.5px;
  }

  button:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
  }

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    select:focus,
    textarea:focus,
    input:focus {
      font-size: 16px;
    }
  }

  .modal-footer {
    justify-content: space-between !important;
  }

  .errorText {
    color: $system_error_e200;
    display: inline-block;
    margin-left: 4px;
  }

  .date-row {
    &.withoutLabel .floating-label {
      margin-top: 8px;
    }
    label {
      z-index: 1;
    }
    .date-padder {
      padding-left: 6px;
      padding-right: 6px;
    }

    .ant-select {
      position: relative;
      top: 20px;
    }
  }

  .premiumHouseImg {
    max-width: 385px;
    content: url('../../style/_assets/icons/signup/premium/premium-house-desktop.png');
  }

  .socialTickerDots {
    &:before {
      animation: social_ticker_dots 2s linear infinite;
      content: '';
    }
  }

  @media (max-width: $breakpoint-mobile-max) {
    .answer-container {
      padding-top: 12px;
      padding-bottom: 12px;
    }
    .sesameCashModuleDescription {
      font-size: 20px;
    }
    .SesameCashModule {
      .withTitle {
        ul {
          margin-left: 0;
          padding-left: 20px;
        }
        li {
          margin-bottom: 8px;
        }
        .bulletsSection {
          font-size: $font14;
        }
      }
    }
    .premiumHouseImg {
      content: url('../../style/_assets/icons/signup/premium/premium-house-mobile.png');
    }
  }

  .widthTrustSignals {
    width: 40%;

    div {
      width: 100%;
    }

    @media (max-width: $breakpoint-tablet-max) {
      width: 100%;
      div {
        width: 90%;
      }
    }
  }

  @media (min-width: $breakpoint-tablet-max) {
    .widthSlide {
      width: 45% !important;
    }
  }
}
