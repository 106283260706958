@import 'style/vars.module';

.hightlightFeature {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: $red100;
  display: inline-block;

  &.featuredDot {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-bottom: 2px;
  }

  &.featuredLabel {
    color: $white;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.5px;
    padding: 2px 8px;
    border-radius: 15px;
    text-align: center;
    line-height: 1;
  }

  &.expandedPosition {
    top: 16px;
    right: 16px;
  }
}
