@import 'style/system_colors';
@import 'customBrandStylePath/_brand';

.menuListContainer {
  position: relative;
  height: 100%;

  .custom-menu-item-group-title {
    background-color: $primary_light;

    .ant-menu-item-group-title {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  .ant-menu-title-content a:focus-visible {
    border: 1px solid #292cb7;
  }

  .seperator {
    a {
      border-bottom: 1px solid $gray3;
    }
  }

  .actionLink {
    @media (max-width: 1279px) {
      padding: 0;
    }
  }

  .redDotCircle {
    background-color: $red2;
    border-radius: 50px;
    top: -5px;
    left: 5px;

    &.collapsed {
      top: -30px;
      left: 80%;
    }
  }

  .redDot {
    position: relative;

    &.goalsDot {
      .goalsNumber {
        color: $system_neutral_n050;
      }
    }

    @media (max-width: 1279px) {
      position: absolute;
      right: 16px;
    }

    &.menuCollapsed {
      position: absolute;
      top: 0;
      right: 16px;
    }
  }

  .hide-empty-title {
    ul {
      li {
        &.ant-menu-item {
          height: 60px;
        }
      }
    }
  }

  .bureauLabel {
    padding-right: 10px !important;
  }
  .premiumMenuItem {
    height: 20px;
    left: 18px;
    top: 286px;
    @include font-base;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 32px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: 4px;
    color: $secondary_cta_bg_light;

    &.paymentPendingUser,
    &.reactivatePendingCancellationUser {
      font-size: 9px;
      letter-spacing: unset;
    }

    &.letterSpacing0 {
      letter-spacing: 0;
    }
  }

  .topShadow {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  }

  .bottomShadow {
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.08);
    z-index: 1;
  }

  .mainMenu {
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: -1px;
  }

  .plusMainmenu {
    background-color: $secondary_light;
  }

  .navbar-logo-mobile-container {
    display: none;
    height: auto;
  }

  .newMenuItemPill {
    width: 42px;
    font-size: 10px;
    height: 20px;
    color: $system_neutral_n050 !important;
    background-color: $red100;
    line-height: 20px;
    text-transform: uppercase;
    border-radius: 12px;
    text-align: center;
  }

  .ant-menu-item {
    .hightlightFeature {
      position: initial;
    }
  }

  .sesameCashMenuText {
    padding-right: $pad34;
  }

  .leftNavItemWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .ant-menu-submenu {
    .ant-menu-submenu-title {
      &:hover {
        span {
          color: $secondary_cta_bg_light !important;
        }

        svg {
          g path:first-child {
            fill: $secondary_cta_bg_light;
          }

          g path:nth-child(2) {
            fill: $secondary_cta_bg_light;
          }
        }

        i.ant-menu-submenu-arrow {
          color: $system_neutral_n025;

          &::after,
          &::before {
            color: $secondary_cta_bg_light;
          }
        }

        &:active,
        &:focus {
          background-color: $system_neutral_n000;
        }
      }
    }
  }

  &.plusMenuListContainer {
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:not(:active) {
      background-color: $secondary_light;
    }

    a.selected {
      svg {
        &#Layer_1 g path:nth-child(1) {
          fill: $primary_cta_bg_dark !important;
        }

        g path:nth-child(2) {
          fill: $primary_cta_bg_dark !important;
        }
      }

      .outline {
        display: none;
      }

      .filled {
        display: initial;
      }

      .idTheftInsurance {
        svg {
          path:first-child {
            fill: $primary_cta_bg_dark !important;
          }
        }
      }
    }

    ul {
      .ant-menu-item-selected {
        &:after {
          border-right: 4px solid $primary_cta_bg_dark;
        }
      }

      a {
        &.selected {
          span {
            font-weight: bold;
            color: $primary_cta_bg_dark !important;
          }
        }
      }

      .ant-menu-item {
        &:active,
        &:focus {
          background-color: $secondary_light;
        }
      }
    }

    .ant-menu-submenu {
      font-size: 16px;

      .ant-menu {
        .ant-menu-item {
          background-color: $secondary_light;

          &:active,
          &:focus {
            background-color: $secondary_light;
          }
        }
      }

      .ant-menu-submenu-title {
        height: 48px;
        margin-top: 0;
        margin-bottom: 0;

        span {
          color: $system_neutral_n025 !important;
        }

        i.ant-menu-submenu-arrow {
          color: $system_neutral_n000;
        }

        &:hover {
          span {
            color: $primary_cta_bg_dark !important;
          }

          svg {
            g path:first-child {
              fill: $primary_cta_bg_dark;
            }
          }

          i.ant-menu-submenu-arrow {
            color: $system_neutral_n025;

            &::after,
            &::before {
              color: $primary_cta_bg_dark;
            }
          }

          &:active,
          &:focus {
            background-color: $secondary_light;
          }
        }
      }

      &.ant-menu-submenu-open {
        .ant-menu-submenu-title {
          span {
            color: $system_neutral_n025;
          }

          i {
            color: $system_neutral_n025;
          }
        }
      }
    }
  }

  .plusUserLabel {
    color: $system_neutral_n050 !important;
  }

  ul {
    .ant-menu-item {
      &:not(.navbar-logo-mobile-container) {
        height: 48px;
        margin-top: 0;
        margin-bottom: 0;

        &.subMenuItem {
          height: 36px;
        }
      }

      &:active,
      &:focus {
        background-color: $gray005;
      }
    }

    a {
      &.selected {
        span {
          font-weight: bold;
          color: $secondary_cta_bg_light;
        }
      }
    }

    .ant-menu-item-selected {
      &:after {
        border-right: 4px solid $secondary_cta_bg_light;
      }
    }

    /* hide vertical bar for child items */
    .subMenuItem {
      &.ant-menu-item-selected {
        &:after {
          display: none;
        }
      }
    }

    &.ant-menu-inline-collapsed {
      width: 80px;

      > .ant-menu-item {
        padding: 0 0 !important;
        height: 56px;

        &:not(.ant-menu-item-selected) {
          /* border for child items */
          a.selected {
            &:after {
              border-right: 2px solid $secondary_cta_bg_light;
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              content: '';
            }
          }
        }
      }

      .policyList {
        display: none;
      }

      &.bottomMenu {
        flex: 0 0 110px;
      }

      &.bottomMenuOcf {
        flex: 0 0 68px;
      }

      > .ant-menu-item .anticon + .Badge {
        display: none;
      }

      .numbered {
        line-height: 16px;
        font-size: 10px;
        height: 16px;
        width: 100%;
        max-width: 16px;
        margin: 0 8px 30px 0;
      }

      .hightlightFeature {
        position: absolute;
        right: 15px;
      }

      .leftNavItemWrapper {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .anticon {
          height: 24px;
          line-height: 0;
        }

        span {
          font-size: 8px;
          margin-top: 2px;
          opacity: 1;
          text-align: center;
          max-width: initial;
          line-height: initial;
        }

        .sesameCashMenuText {
          padding-right: 0;
        }
      }

      .ant-menu-item-selected::after {
        transform: scaleY(1);
        opacity: 1;
        border-right: 2px solid $secondary_cta_bg_light;
      }

      .hide-empty-title {
        .ant-menu-item-group-title {
          display: none;
        }
      }
    }

    .policyList {
      a {
        display: inline-block;
        position: relative;
        width: auto;
        left: 24px;
      }
    }
  }

  a {
    &:link,
    &:visited {
      text-decoration: none;
    }

    .menuButton {
      font-size: $font24 !important;
    }

    .menuIconFilled {
      display: none;
    }

    .menuButton.plusIcon {
      path {
        color: $system_neutral_n050;
      }

      g path:nth-child(2) {
        fill: $system_neutral_n050;
      }
    }

    .idTheftInsurance.plusIconWhite {
      svg {
        path {
          fill: $system_neutral_n050;
        }
      }
    }

    .anticon.menuButton.outline {
      display: inline;
    }

    .anticon.menuButton.filled {
      display: none;
    }

    &.selected {
      svg {
        g path:nth-child(2) {
          fill: $secondary_cta_bg_light;
        }
      }

      .outline {
        display: none;
      }

      .filled {
        display: initial;
      }

      .idTheftInsurance {
        svg {
          path:first-child {
            fill: $secondary_cta_bg_light;
          }
        }
      }
    }
  }

  .ant-menu {
    .ant-menu-item-group {
      .ant-menu-item-group-list {
        @media (hover: hover) {
          .ant-menu-item:hover {
            .ant-menu-title-content {
              a {
                .leftNavItemWrapper {
                  .menuButton.plusIcon {
                    path {
                      color: $primary_cta_bg_dark;
                    }

                    g path:nth-child(2) {
                      fill: $primary_cta_bg_dark;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .ant-menu-item-group {
    .ant-menu-item-group-list {
      .ant-menu-item {
        .ant-menu-title-content {
          a {
            .plusIconWhite {
              g path:nth-child(2) {
                fill: $system_neutral_n050;
              }
            }
          }

          .ant-menu-item {
            .rentReporting.plusIcon {
              rect {
                fill: none;
              }

              path {
                fill: $system_neutral_n050 !important;
              }

              path:hover {
                fill: $primary_cta_bg_light !important;
              }
            }
          }
        }
      }

      .ant-menu-item:hover {
        .ant-menu-title-content {
          a {
            .plusIconWhite {
              g path:nth-child(2) {
                fill: $primary_cta_bg_dark;
              }
            }
          }

          .ant-menu-item {
            .rentReporting.plusIcon {
              path {
                fill: $primary_cta_bg_dark !important;
              }
            }
          }
        }
      }
    }

    .tagStyle {
      padding: 6px 10px;
      text-transform: uppercase;
      display: inline-block;

      &.non-qualified {
        background-color: $gray1;
        color: $system_neutral_n250;
      }

      &.qualified {
        background-color: $secondary_light;
        color: $system_neutral_n050;
      }
    }
  }

  .plusBottomMenu {
    background-color: $secondary_light;
  }

  .bottomMenu {
    bottom: 0;
    flex: 0 0 148px;
  }

  .bottomMenuOcf {
    bottom: 0;
    flex: 0 0 116px;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:not(:active) {
    background-color: $system_neutral_n000;
  }

  .premiumLabel {
    .premiumLabelInner {
      width: 28px;
      height: 28px;
      border-radius: 14px;

      &.non-qualified {
        background-color: $system_neutral_n100;
        color: $system_neutral_n250;

        .premiumLabelIcon {
          g path:nth-child(2) {
            fill: $system_neutral_n050;
          }

          .idTheftInsurance {
            svg {
              path:first-child {
                fill: $system_neutral_n050;
              }
            }
          }
        }
      }

      &.qualified {
        background-color: $purple0;
      }
    }

    .premiumLabelText {
      &.non-qualified {
        color: $system_neutral_n250;
      }

      &.qualified {
        color: $gray400;
      }
    }

    .menuIconSpacer {
      padding-left: 10px;
    }

    .isPlusNavLabelClass {
      &.non-qualified {
        color: $system_neutral_n250;
      }

      &.qualified {
        color: $system_neutral_n250;
      }
    }
  }

  @media (hover: hover) {
    .ant-menu-item :hover {
      span {
        color: $secondary_cta_bg_light;
      }

      svg {
        g path:nth-child(2) {
          fill: $secondary_cta_bg_light;
        }
      }

      .idTheftInsurance {
        svg {
          path:first-child {
            fill: $secondary_cta_bg_light;
          }
        }
      }
    }
  }

  &.plusMenuListContainer {
    @media (hover: hover) {
      .ant-menu-item:hover {
        span {
          color: $primary_cta_bg_dark !important;
        }

        svg {
          g path:nth-child(2) {
            fill: $primary_cta_bg_dark;
          }
        }

        .idTheftInsurance {
          svg {
            path:first-child {
              fill: $primary_cta_bg_dark;
            }
          }
        }

        .chatSupport {
          g path:nth-child(n):nth-child(-n + 4) {
            fill: $primary_cta_bg_dark;
          }
        }
      }
    }
  }

  .ant-menu-item {
    .rentReporting {
      path {
        fill: $neutral250;
      }
    }

    &.non-qualified,
    &.non-qualified :hover {
      span {
        color: $system_neutral_n250;

        &.rentReporting {
          svg {
            path {
              fill: $system_neutral_n100 !important;
            }
          }
        }
      }

      svg {
        g path:nth-child(2) {
          fill: $system_neutral_n200;
        }
      }

      .idTheftInsurance {
        svg {
          path:first-child {
            fill: $system_neutral_n200;
          }
        }
      }

      .anticon.menuButton.outline {
        display: initial;
      }

      .anticon.menuButton.filled {
        display: none;
      }
    }

    &.ant-menu-item-selected.non-qualified {
      span {
        color: $secondary_cta_bg_light;
      }

      svg {
        g path:nth-child(2) {
          fill: $secondary_cta_bg_light;
        }
      }
    }
  }

  .ant-menu-item:not(.ant-menu-item-selected) :active {
    span {
      color: $gray7;
    }

    svg {
      g path:nth-child(2) {
        fill: $gray7;
      }
    }

    .idTheftInsurance {
      svg {
        path:first-child {
          fill: $gray7;
        }
      }
    }
  }

  .ant-menu-item {
    .Badge {
      right: 0;
    }
  }

  .logoMenu {
    height: initial;
  }

  .ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon + span {
    opacity: 1;
    padding: 0;
    margin: 0;
  }

  .ant-menu-title-content {
    width: 100%;
  }

  .plusFooterLink {
    color: $secondary_cta_text_light;

    &:hover {
      color: $primary_cta_bg_dark !important;
    }
  }

  .footerLink {
    color: $secondary_cta_bg_light;

    &:hover {
      color: $secondary_cta_bg_light;
    }
  }
}

//fixed below
@media (max-width: $breakpoint-tablet-max) {
  .menuListContainer {
    .bottomMenu {
      flex: 0 0 92px;
    }

    .bottomMenuOcf {
      flex: 0 0 60px;
    }

    .seperator {
      a {
        border-bottom: none;
      }
    }

    .navbar-logo-mobile-container span {
      display: flex;
      margin-top: 0;
      margin-bottom: 0 !important;
      padding-top: 0;
      padding-bottom: 4px;
      justify-content: space-between;
      overflow: visible;
      position: sticky;
      top: 0;
      background-color: $system_neutral_n000;
      z-index: 3;

      .premiumIcon {
        background-color: $purple0;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        position: relative;
        top: 7px;
      }
    }

    &.plusMenuListContainer {
      .ant-menu {
        .ant-menu-item {
          .ant-menu-title-content {
            background-color: $secondary_light;
          }
        }
      }

      .ant-menu-submenu {
        .ant-menu-submenu-title {
          padding-left: 16px !important;

          i {
            color: $system_neutral_n025;
          }
        }
      }

      .ant-menu-sub.ant-menu-inline {
        background: $secondary_light;
      }
    }

    .navbar-logo-mobile {
      width: 150px;
      height: 40px;
      margin-top: 5px;

      & > svg {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
      }
    }

    ul {
      .ant-menu-item-selected::after {
        border-right: 3px solid $secondary_cta_bg_light;
      }
    }
  }
}

.ant-tooltip-placement-right {
  .leftNavItemWrapper {
    div {
      span {
        display: inline;
      }
    }
  }
}

.ant-tooltip.ant-menu-inline-collapsed-tooltip {
  .ant-tooltip-content .Badge {
    display: none;
  }

  .ant-tooltip-content .featuredDot {
    display: none;
  }

  .ant-tooltip-inner {
    span {
      color: $gray050;
    }
  }

  &:not(.ant-tooltip-hidden) {
    .ant-tooltip-content .ant-tooltip-inner {
      .leftNavItemWrapper {
        .menuButton {
          display: none;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-tablet-max) {
  .menuListContainer {
    .navbar-logo-mobile {
      margin-bottom: 16px;
      width: 160px;
    }

    .ant-menu-inline {
      .ant-menu-item {
        padding: 0 16px !important;

        &.no-link {
          padding-left: 0 !important;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-mobile-max) {
  .menuListContainer {
    .ant-menu-inline {
      .policyList {
        a {
          left: 16px;
        }
      }
    }

    .ant-menu-submenu {
      .ant-menu-submenu-title {
        padding: 0 16px !important;
      }
    }
  }
}
