@import 'style/vars.module';

body {
  /* Disables pull-to-refresh and overscroll glow effect.
		Still keeps swipe navigations. */
  overscroll-behavior-y: none;
}

html,
body {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: $font-family-base, $font-family-bold, Arial, Verdana, Helvetica,
    sans-serif;
  color: $gray8;
  font-size: 16px;
  background-color: $gray0 !important;
}

a {
  &:hover {
    color: $blue300;
    text-decoration: none !important;
  }
}

input:hover:not([type='checkbox']) {
  cursor: text;
}

a {
  color: $secondary_cta_bg_light;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:link,
a:visited {
  text-decoration: none;
}

h1 {
  font-size: 45px;
  font-weight: 400;
  line-height: 54px;
}

h2 {
  font-size: 34px;
  font-weight: 400;
  line-height: 41px;
}

h3 {
  font-size: 23px;
  font-weight: 300;
}

h4 {
  font-size: 17px;
  font-weight: 300;
}

h5 {
  font-size: 12px;
  font-weight: 300;
}

h6 {
  font-size: 10px;
  font-weight: 300;
}

p {
  padding-top: 10px;
  padding-bottom: 10px;
}
