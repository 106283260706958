@import 'style/vars.module';

.sticky-footer-container {
  position: sticky;
  bottom: 0;
  background: $white;
  z-index: 4;
  padding: 16px 24px 16px 24px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}
