@import "style/vars.module";

.Card {
  &.warning {
    background: $yellow025;
    border: 1px solid $yellow300;
    box-sizing: border-box;
    border-radius: 4px;
  }
  &.danger {
    border: solid 1px $red6;
    background-color: $red0;
    box-sizing: border-box;
    border-radius: 4px;
  }

  &.shadowed016 {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
    border-radius:8px;
  }

  &.grayBordered {
    border-radius: 8px;
    background-color: $neutral005;
    border: 1px solid $neutral050;
  }
  &.whiteBordered {
    border-radius: 8px;
    border: 1px solid $neutral050;
  }
}