.noWrapText {
  white-space: nowrap;
}

.normal {
  font-style: normal;
}

.italic {
  font-style: italic;
}

.bold {
  @include font-bold;
  font-weight: 600;
}
.normal-weight {
  font-weight: 400;
}
.text-weight-800 {
  font-weight: 800;
}

.fontLato {
  @include font-base;
}

.fontLatoNormal700 {
  @include font-base;
  font-style: normal;
  font-weight: 700;
}

.fontLatoNormal400 {
  @include font-base;
  font-style: normal;
  font-weight: 400;
}

.fontLatoNormal600 {
  @include font-base;
  font-style: normal;
  font-weight: 600;
}

.fontLatoNormal800 {
  @include font-base;
  font-style: normal;
  font-weight: 800;
}

.fontLatoNormal900 {
  @include font-base;
  font-style: normal;
  font-weight: 900;
}

.fontLatoBold {
  @include font-bold;
  font-weight: normal;
}

.fontLatoBlack {
  @include font-black;
}

.notBold {
  @include font-regular;
  font-weight: normal;
}

.lineHeight-1 {
  line-height: 1;
}

.lineHeight-1-18 {
  line-height: 1.18;
}

.lineHeight-1-21 {
  line-height: 1.21;
}

.lineHeight-1-33 {
  line-height: 1.33;
}

.lineHeight-1-5 {
  line-height: 1.5;
}

.lineHeight-0-44 {
  line-height: 0.44;
}

.lineHeight-1-4 {
  line-height: 1.4;
}

.lineHeight-1-6 {
  line-height: 1.6;
}

.lineHeight-1-43 {
  line-height: 1.43;
}

.underline {
  text-decoration: underline;
}

@media (max-width: $breakpoint-tablet-max) {
  .mobileCenter {
    text-align: center;
  }
}

@media (max-width: $breakpoint-mobile-max) {
  .m-fontLatoBold {
    @include font-bold;
    font-weight: normal;
  }
  .m-text-center {
    text-align: center;
  }

  .m-lineHeight-1 {
    line-height: 1;
  }

  .m-lineHeight-1-33 {
    line-height: 1.33;
  }

  .m-lineHeight-1-5 {
    line-height: 1.5;
  }

  .m-lineHeight-1-4 {
    line-height: 1.4;
  }
  .m-lineHeight-1-43 {
    line-height: 1.43;
  }
}

.fontRobotoThin {
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  font-style: normal;
}

.fontRobotoLight {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-style: normal;
}

.fontRobotoRegular {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.fontRobotoMedium {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-style: normal;
}

.fontRobotoBold {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-style: normal;
}

.fontRobotoBlack {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-style: normal;
}
