@import 'style/vars.module';

@mixin yellow075-tag {
  background-color: $yellow075;
  color: $gray500;
}

.tagStyle {
  padding: 4px 12px;
  border-radius: 15px;
  -moz-border-radius: 15px;
  text-align: center;
  @include font-bold;
  font-size: $font12;
  text-transform: uppercase;

  &.boldTag {
    @include yellow075-tag;
  }

  &.newTag {
    @include yellow075-tag;
  }

  &.offerTag {
    @include yellow075-tag;
    padding: 4px 10px;
    border-radius: 12px;
    line-height: 1.33;
  }

  @media (min-width: $breakpoint-desktop-min) {
    &.boldTag {
      padding: 8px 16px;
      font-size: $font14;
    }
  }
}
