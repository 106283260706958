/** _icons **/

.nu-bullet-1 {
  background-image: url('./_assets/icons/cash/art-bullet-1.svg');
}

.nu-bullet-2 {
  background-image: url('./_assets/icons/cash/art-bullet-2.svg');
}

.nu-bullet-3 {
  background-image: url('./_assets/icons/cash/art-bullet-3.svg');
}

.CSiconz {
  font-family: 'csIcon', Arial, sans-serif !important;
  font-weight: normal !important;
}
