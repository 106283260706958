/** Header Component Styles **/
@import 'style/vars.module';

.main-main-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;

  .main-nav-container {
    height: 56px;
    background-color: $white;
    z-index: 4;
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);

    .flex-container {
      display: flex;
      flex-direction: row;
      height: 100%;
    }

    .left {
      display: flex;
      align-items: center;
    }

    .menuDotIconSection {
      position: relative;
    }

    .menuDot {
      width: 8px;
      height: 8px;
      background-color: $red100;
      border-radius: 16px;
      position: absolute;
      right: 29px;
      top: -4px;
    }

    .navbar-logo {
      width: 140px;
      height: 44px;
      background-position: 0 0;
      background-size: 140px;
      margin-left: 10px;
      margin-top: -10px;

      & > svg {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
      }
    }

    .desktopHamberger {
      margin-left: 24px;
    }

    .freeTrialWrap {
      flex-direction: column;
    }

    .ocfLoginArea {
      right: 24px;

      .ocfLoginButton {
        height: 40px;
      }
    }
  }

  .main-body-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    padding-top: 56px;
    flex-grow: 1;

    &.isSignup {
      background-color: $white;
      display: block;
      height: 100%;
    }

    &.isUnsubscribe {
      background-color: $white;
      display: block;
      height: 100%;
    }

    &.isPublic {
      background-color: $white;
      display: block;
      height: 94vh;
    }

    &.isPremiumStarterPage,
    &.isActionsModuleSelectedPage {
      padding-top: 0;
    }

    &.isRecurlyCreditReportYUHybridUser,
    &.isHidingHeaderCompletely {
      padding-top: 0px !important;
    }

    &.isPlusConfirmationPage {
      min-height: 100vh;
      height: 100%;
    }

    .header-menu-container {
      display: initial;
    }

    .header-menu-container-mobile {
      display: none;
    }

    .fake-header-menu-container-thin {
      width: 72px;
      min-width: 72px;
    }

    .fake-header-menu-container-thick {
      min-width: 256px;
      width: 256px;
    }
  }
}

.plus-main-nav-container {
  background-color: $secondary_light !important;
}

@media (min-width: $breakpoint-tablet-max) {
  .main-main-container {
    .main-nav-container {
      justify-content: space-between;
    }
  }
}

@media (max-width: $breakpoint-tablet-max) {
  .main-main-container {
    height: 100%;
    width: 100%;

    .left {
      height: 100%;
      width: 100%;
    }

    .right {
      display: none;
    }

    .navbar-logo.secure {
      display: none;
    }

    .main-nav-container {
      position: fixed;
      width: 100%;

      .desktopHamberger {
        display: none;
      }
    }

    .main-body-container {
      .grayLayer {
        z-index: 6;
        top: 0;
        background-color: rgba(53, 60, 69, 0.4);
        width: 100%;
        height: 100%;
        position: fixed;
      }

      .header-menu-container {
        display: none;
      }

      .header-menu-container-mobile {
        display: initial;
      }

      .fake-header-menu-container-thin {
        display: none;
      }

      .fake-header-menu-container-thick {
        display: none;
      }

      &.isPublic {
        height: 96vh;
      }
    }
  }
}
